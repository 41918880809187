<template>
  <div class="home">
    <v-progress-linear
      :active="preloader"
      indeterminate
      color="red"
    ></v-progress-linear>
    <v-container>
      <div justify="center" class="text-center">
        <p class="title font-weight-regular">{{today}}</p>
      </div>
      <v-row class="mt-8">
      <v-col cols="12" md="8">
        <v-card
          v-if="!preloader"
          outlined
          shaped
          color="orange lighten-5"
        >
          <v-list-item>
            <v-list-item-content class="headline font-weight-medium">
                Dolgozókkal kapcsolatos aktuális értesítések
            </v-list-item-content>
            <v-icon
              x-large
              color="black"
            >
              face
            </v-icon>
          </v-list-item>
            <v-card-text>
              <v-card-subtitle class="title font-weight-light" v-if="beosztasok == '' && workers != ''">
                {{lazy_text}}
              </v-card-subtitle>
              <v-card-subtitle class="title font-weight-light" v-if="workers == ''">
                A rendszer használatához először rögzíteni kell legalább egy céget, munkahelyet és dolgozót!
                <br><br>
                <v-btn
                  depressed
                  color="primary"
                  @click="goToSettings()"
                >
                  Ugrás a törzsadatokhoz
                </v-btn>
              </v-card-subtitle>
            <v-expansion-panels flat focusable v-if="beosztasok != ''">
              <v-expansion-panel
                v-for="(item, index) in beosztasok"
                :key="index"
              >
                <v-expansion-panel-header class="title font-weight-regular">
                  {{ index }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <ul>
                    <li v-for="item in item['error']" :key="item.index">
                      {{ item }}
                    </li>
                  </ul>
                  <br>
                  <v-btn
                    color="primary"
                    outlined
                    @click="goToWorkerSchedule(item.worker_id)"
                  >
                    Ugrás a beosztáshoz
                  </v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card
          outlined
          shaped
          color="blue lighten-5"
        >
        <v-list-item>
          <v-list-item-content class="headline font-weight-medium">
              Kérdése van? Segítünk!
          </v-list-item-content>
          <v-icon
            x-large
            color="black"
          >
            chat
          </v-icon>
        </v-list-item>
        <v-container>
          <v-form
            ref="contact_form"
            v-model="contact_valid"
          >
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              background-color="white"
              outlined
              v-model="contact_email"
              label="E-mail címe"
              :rules="emailRules"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-textarea
              background-color="white"
              v-model="contact_message"
              outlined
              name="input-7-4"
              label="Üzenet"
              :rules="required"
            ></v-textarea>
          </v-col>
        </v-row>
        </v-form>
        <v-card-actions>

          <v-spacer></v-spacer>

          <v-btn
            depressed
            color="primary"
            :disabled="!contact_valid || mail_loader"
            :loading="mail_loader"
            @click="Send()"
          >
            Küldés
          </v-btn>
        </v-card-actions>
        </v-container>

        </v-card>
      </v-col>
      </v-row>

    </v-container>

    <v-snackbar
      v-model="error_snackbar"
      color="red"
    >
      {{ error_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="error_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="success_snackbar"
    >
      {{ success_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="success_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import EventBus from '@/EventBus';
import firebase from '@/firebaseinit';
import { getAuth } from "firebase/auth";
const auth = getAuth(firebase);

export default {
  name: 'Home',
  components: {
  },
  data: () => ({
    beosztasok: [],
    lazy_text: '',
    today: new Date().toLocaleDateString(undefined, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'}),
    interval: null,
    time: null,
    workers: [],
    preloader: false,
    contact_email: '',
    contact_message: '',
    required: [
      v => !!v || 'A mező kitöltése kötelező!'
    ],
    emailRules: [
      v => !!v || 'A mező kitöltése kötelező!',
      v => /.+@.+\..+/.test(v) || 'Az e-mail cím formátuma nem megfelelő!',
    ],
    contact_valid: true,
    error_snackbar: false,
    error_message: '',
    success_message: '',
    success_snackbar: false,
    mail_loader: false,
  }),
  methods: {
    getJWT: async function() {
      var jwt = "";
      await auth.currentUser.getIdToken().then(function(idToken) {
        jwt = idToken;
      }).catch(function(error) {
        // Handle error
        console.log(error);
      });
      return jwt;
    },
    getBeosztas: async function () {
      this.preloader = true;
      const jwt = await this.getJWT();
      this.$http.post('/functions/dashboard', {
      token: jwt
      })
      .then((response) => {
        this.beosztasok = response.data;
        console.log(response.data);
        if (response.data == '') {
          this.lazy_text = "Jelenleg nincsenek megoldandó feladatok, lazíthat! :-)";
        }
        this.preloader = false;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    getWorkers: async function () {
      this.preloader = true;
      const jwt = await this.getJWT();
      this.$http.post('/datas/workersForDatas', {
      token: jwt
      })
      .then((response) => {
        this.workers = response.data;
        this.preloader = false;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    openingHours: async function () {
      const jwt = await this.getJWT();
      this.$http.post('/functions/openHours', {
      token: jwt
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    goToWorkerSchedule: async function (datas) {
      await this.$router.push({ path: '/beosztas' });
      EventBus.$emit('goToWorkerSchedule', datas)
    },
    goToSettings: async function () {
      await this.$router.push({ path: '/adatok' });
    },
    checkAutoSettings: async function () {
      this.preloader = true
      const jwt = await this.getJWT();
      this.$http.post('/functions/checkAutoSettings', {
      token: jwt
      })
      .then((response) => {
        this.preloader = false;
        this.contact_email = response.data[0]['email'];
        this.$refs.contact_form.resetValidation()
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    Send: async function () {
      this.mail_loader = true
      const jwt = await this.getJWT();
      this.$http.post('/functions/send_innerContractForm', {
      token: jwt,
      email: this.contact_email,
      message: this.contact_message
      })
      .then((response) => {
        this.mail_loader = false;
        if (response.data['success'] == true) {
          this.success_message = response.data['message'];
          this.success_snackbar = true;
          this.$refs.contact_form.resetValidation()
          this.contact_message = '';
        } else {
          this.mail_loader = false;
          this.error_message = response.data['message'];
          this.error_snackbar = true;
          this.$refs.contact_form.resetValidation()
          this.contact_message = '';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    },
  },
  mounted() {
    this.getBeosztas();
    this.getWorkers();
    this.checkAutoSettings();
  },
  beforeDestroy() {
  // prevent memory leak
    clearInterval(this.interval)
  },
  created() {
    EventBus.$on('refreshDashboard', () => {
          this.getBeosztas();
    }),
    EventBus.$on('refreshWorkersonDashboard', () => {
          this.getWorkers();
    }),

    this.interval = setInterval(() => {
    this.today = new Date().toLocaleDateString(undefined, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
    }, 1000)
  },
  watch: {
    today() {
      this.getBeosztas();
    }
  },
  computed: {
  }
}
</script>
