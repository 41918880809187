<template lang="html">
  <div class="customer-component">

   <v-container>

     <v-row class="mt-8">
     <v-col cols="12">
       <v-card
         outlined
         shaped
         color="orange lighten-5"
       >
         <v-list-item>
           <v-list-item-content class="headline font-weight-medium">
               Törzsadatok
           </v-list-item-content>
         </v-list-item>
           <v-container>
             <v-expansion-panels focusable popout>
              <v-expansion-panel>
                <v-expansion-panel-header class="title font-weight-regular" disable-icon-rotate>
                  Cégek
                  <template v-slot:actions>
                    <v-icon>
                      business
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-container>
                    <v-text-field
                      v-model="search_company"
                      append-icon="mdi-magnify"
                      label="Keresés"
                      outlined
                    ></v-text-field>
                    </v-container>
                    <v-data-table
                      :headers="headers_comp"
                      :items="companies"
                      :search="search_company"
                      @click:row="getCompany($event)"
                    ></v-data-table>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green"
                        outlined
                        @click="newCompany()"
                      >
                        Hozzáadás
                        <v-icon
                          right
                          dark
                        >
                          add_circle_outline
                        </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="title font-weight-regular" disable-icon-rotate>
                  Munkahelyek
                  <template v-slot:actions>
                    <v-icon>
                      store
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-container>
                    <v-text-field
                      v-model="search_place"
                      append-icon="mdi-magnify"
                      label="Keresés"
                      outlined
                    ></v-text-field>
                    </v-container>
                    <v-data-table
                      :headers="headers_place"
                      :items="places"
                      :search="search_place"
                      @click:row="getPlace($event)"
                    ></v-data-table>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green"
                        outlined
                        @click="newPlace()"
                      >
                        Hozzáadás
                        <v-icon
                          right
                          dark
                        >
                          add_circle_outline
                        </v-icon>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="title font-weight-regular" disable-icon-rotate>
                  Dolgozók
                  <template v-slot:actions>
                    <v-icon>
                      face
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card flat>
                    <v-container>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Keresés"
                      outlined
                    ></v-text-field>
                    </v-container>
                    <v-data-table
                      :headers="headers"
                      :items="customers"
                      :search="search"
                      @click:row="getWorker($event)"
                    ></v-data-table>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="green"
                        outlined
                        @click="newWorker()"
                        :disabled="companies == '' || places == ''"
                      >
                        Hozzáadás
                        <v-icon
                          right
                          dark
                        >
                          add_circle_outline
                        </v-icon>
                      </v-btn>
                    </v-card-actions>
                    <v-alert
                      v-if="companies == '' || places == ''"
                      border="left"
                      colored-border
                      type="error"
                      outlined
                      dense
                      class="text-body-2 text-justify"
                    >
                      Dolgozó hozzáadásához először rögzíteni kell legalább egy céget és munkahelyet!
                    </v-alert>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
         </v-container>
       </v-card>
     </v-col>
     </v-row>
     <br>
     <v-row justify="center" class="mt-0" v-if="customers != ''">
       <v-btn
         color="green"
         depressed
         dark
         @click="goToSchedule()"
       >
         Beosztás Készítése
         <v-icon
           right
           dark
         >
           add_circle_outline
         </v-icon>
       </v-btn>
     </v-row>
     <br>
   </v-container>

  <v-row justify="center">
    <v-dialog
      v-model="new_company_dialog"
      persistent
      scrollable
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Cég rögzítése</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="closeCompDialog()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form
              ref="company_form"
              v-model="comp_valid"
            >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="comp_name"
                  label="Cégnév"
                  :rules="required"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="comp_address"
                  label="Székhely"
                  :rules="required"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="comp_email"
                  label="E-mail cím"
                  :rules="[rules.required, rules.email]"
                  outlined
                  hint="Erről küldi a program a különböző rendszerüzeneteket a dolgozóknak."
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="comp_tax"
                  label="Adószám"
                  :rules="tax_code_rules"
                  v-mask="'########-#-##'"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="comp_id"
            color="red"
            outlined
            @click="del_comp_dialog = true"
            :disabled="preloader"
          >
            Töröl
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            @click="saveCompany()"
            :disabled="!comp_valid || preloader"
            :loading="preloader"
          >
            Rögzít
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="new_place_dialog"
      persistent
      width="650px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Munkahely rögzítése</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="closePlaceDialog()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form
              ref="place_form"
              v-model="place_valid"
            >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="place_nev"
                  label="Munkahely elnevezése"
                  :rules="required"
                  outlined
                  hint="Rövid elnevezés javasolt, a munkaügyi dokumentumokon a teljes cím fog szerepelni!"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="place_address"
                  label="Cím"
                  :rules="required"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            </v-form>
            <v-expansion-panels focusable popout>
            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate class="blue--text">
                Tipp!
                <template v-slot:actions>
                  <v-icon color="info">
                    lightbulb_circle
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="text-body-2 text-justify">
                Amennyiben egy adott munkahelyen több elkülönült "részmunkahely" (pl. osztály, részleg, vagy vendéglátásban pl. pult, konyha, stb.) van, érdemes egy munkahely címet többször, más-más munkahely elnevezéssel rögzíteni. Így a beosztások áttekintésekor láthatja, hogy minden szükséges helyre van-e beosztva dolgozó, ugyanakkor a munkaügyi dokumentumokon továbbra is csak a munkahely valós címe kerül feltüntetésre.
              </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="place_id"
            color="red"
            outlined
            @click="del_place_dialog = true"
            :disabled="preloader"
          >
            Töröl
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            @click="savePlace()"
            :disabled="!place_valid || preloader"
            :loading="preloader"
          >
            Rögzít
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="new_worker_dialog"
      persistent
      scrollable
      width="650px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Dolgozó rögzítése</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="closeWorkerDialog()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form
              ref="worker_form"
              v-model="worker_valid"
            >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="worker_nev"
                  label="Név"
                  :rules="required"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="worker_place"
                  :items="places"
                  item-text="rovid_nev"
                  item-value="place_id"
                  label="Munkahely"
                  outlined
                  clearable
                ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="worker_comp"
                  :items="companies"
                  item-text="comp_name"
                  item-value="comp_id"
                  label="Cég"
                  outlined
                  :rules="required"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  outlined
                  v-model="worker_email"
                  label="E-mail cím"
                  :rules="[rules.w_email]"
                  append-icon="mail_outline"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="munkaora"
                  label="Szerződéses heti munkaidő (óra)"
                  outlined
                  v-mask="'##'"
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="szabadsag"
                  label="Éves szabadság (nap)"
                  outlined
                  v-mask="'##'"
                  append-icon="calculate"
                  hint="A számológép ikonra kattintva lehetősége van az éves szabadság kiszámítására!"
                  @click:append="szabadsagCalc_dialog = true"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
              <v-subheader class="text-subtitle-1">
              Alapértelmezések beállítása
              </v-subheader>
              <v-divider></v-divider>
              </v-col>
              <v-col cols="12" md="6">
                <v-menu
                  ref="start_time_menu"
                  v-model="start_time_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="start_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                <template v-slot:activator="{ attrs }">
                <v-text-field
                  v-model="start_time"
                  label="Munkaidő kezdete"
                  append-icon="access_time_filled"
                  @click:append="start_time_menu = true"
                  v-bind="attrs"
                  outlined
                  v-mask="'##:##'"
                >
                </v-text-field>
                </template>
                  <v-time-picker
                    v-if="start_time_menu"
                    v-model="start_time"
                    full-width
                    format="24hr"
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="start_time_menu = false"
                  >
                    Mégsem
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.start_time_menu.save(start_time)"
                  >
                    Kiválaszt
                  </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-menu
                  ref="end_time_menu"
                  v-model="end_time_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="end_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ attrs }">
                    <v-text-field
                      v-model="end_time"
                      label="Munkaidő vége"
                      append-icon="access_time_filled"
                      @click:append="end_time_menu = true"
                      outlined
                      v-bind="attrs"
                      v-mask="'##:##'"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="end_time_menu"
                    v-model="end_time"
                    full-width
                    format="24hr"
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="end_time_menu = false"
                  >
                    Mégsem
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.end_time_menu.save(end_time)"
                  >
                    Kiválaszt
                  </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="munkaszunet"
                  label="Munkaközi szünet (perc)"
                  outlined
                  v-mask="'##'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-model="selected_draft_for_worker"
                  :items="drafts"
                  item-text="draft_name"
                  item-value="draft_id"
                  label="Beosztás sablon"
                  outlined
                  clearable
                  append-icon=""
                >
                <template v-slot:append-outer>
                  <v-tooltip
                    top
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">
                        help_outline
                      </v-icon>
                    </template>
                    Beosztás sablon kiválasztása esetén
                    az automatikus munkaidő rögzítés ezt fogja használni,
                    nem az egy héttel korábbi napon rögzített beosztást.
                  </v-tooltip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  inset
                  v-model="disable_auto_schedule"
                  label="Automatikus munkaidő rögzítés tiltása"
                >
                </v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-switch
                  inset
                  v-model="kotetlen"
                  label="Kötetlen munkarend"
                  hint="Kötetlen munkarend alkalmazása esetén a munkáltató nem köteles a dolgozó napi munkaidejét nyilvántartani."
                  persistent-hint
                >
                </v-switch>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="worker_id"
            color="red"
            outlined
            @click="del_worker_dialog = true"
            :disabled="preloader"
          >
            Töröl
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            @click="saveWorker()"
            :disabled="!worker_valid || preloader"
            :loading="preloader"
          >
            Rögzít
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-dialog
    v-model="del_comp_dialog"
    persistent
    max-width="300"
  >
    <v-card>
      <v-card-text class="pt-5 text-subtitle-1">
        Biztosan törölni szeretné a céget?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="del_comp_dialog = false"
        >
          Nem
        </v-btn>
        <v-btn
          color="green"
          text
          @click="delCompany()"
        >
          Igen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="del_place_dialog"
    persistent
    max-width="350"
  >
    <v-card>
      <v-card-text class="pt-5 text-subtitle-1">
        Biztosan törölni szeretné a munkahelyet?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="del_place_dialog = false"
        >
          Nem
        </v-btn>
        <v-btn
          color="green"
          text
          @click="delPlace()"
        >
          Igen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="del_worker_dialog"
    persistent
    max-width="350"
  >
    <v-card>
      <v-card-text class="pt-5 text-subtitle-1">
        Biztosan törölni szeretné a dolgozót?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="del_worker_dialog = false"
        >
          Nem
        </v-btn>
        <v-btn
          color="green"
          text
          @click="delWorker()"
        >
          Igen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="szabadsagCalc_dialog"
    persistent
    scrollable
    width="600"
  >
    <v-card>
      <v-card-title class="text-h6 grey lighten-2">
        Éves szabadság számítása
      </v-card-title>
      <v-card-text class="pt-5 text-subtitle-1">
        <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="birthdate"
            label="Születési év"
            outlined
            v-mask="'####'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="age_alert">
          <p style="color:red;">{{age_alert}}</p>
        </v-col>
        </v-row>
        <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="numOfChilds"
            label="Gyermekek száma (16 év alatt)"
            outlined
            v-mask="'#'"
            hint="Összes 16 év alatti gyermek, beleértve a fogyatékos gyerekeket is."
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="numOfHcChilds"
            label="Fogyatékos gyermekek száma"
            outlined
            v-mask="'#'"
          ></v-text-field>
        </v-col>
        </v-row>
        <v-row>
          <v-switch
            inset
            v-model="banyasz"
            label="A föld alatt állandó jelleggel vagy az ionizáló sugárzásnak kitett munkahelyen naponta legalább három órát dolgozó munkavállaló."
          ></v-switch>
          <v-switch
            inset
            v-model="fogyi"
            label="Megváltozott munkaképességű, fogyatékossági támogatásra jogosult, vagy vakok személyi járadékára jogosult munkavállaló."
          ></v-switch>
        </v-row>
        <v-row>
          Éves alapszabadság: {{calc_szabadsag}}
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="szabadsagCalc_dialog = false"
        >
          Mégsem
        </v-btn>
        <v-btn
          color="green"
          text
          @click = "szabadsag = calc_szabadsag, szabadsagCalc_dialog = false"
        >
          Rögzít
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-snackbar
    v-model="error_snackbar"
    color="red"
  >
    {{ error_message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="error_snackbar = false"
      >
        bezár
      </v-btn>
    </template>
  </v-snackbar>

  <v-snackbar
    v-model="success_snackbar"
  >
    {{ success_message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="pink"
        text
        v-bind="attrs"
        @click="success_snackbar = false"
      >
        bezár
      </v-btn>
    </template>
  </v-snackbar>

  </div>
</template>

<script>
import EventBus from '@/EventBus';
import firebase from '@/firebaseinit';
import { getAuth } from "firebase/auth";
const auth = getAuth(firebase);

  export default {
    data: () => ({
        search: '',
        customers: [],
        headers: [
          { text: 'Név', value: 'nev' },
          { text: 'Heti munkaidő', value: 'munkaora' },
          { text: 'Cég', value: 'comp_name' },
          { text: 'Munkahely', value: 'rovid_nev' },
        ],
        headers_comp: [
          { text: 'Név', value: 'comp_name' },
          { text: 'Székhely', value: 'comp_address' },
          { text: 'Adószám', value: 'comp_tax' },
        ],
        headers_place: [
          { text: 'Elnevezés', value: 'rovid_nev' },
          { text: 'Cím', value: 'cim' },
        ],
        uid: '',
        error_snackbar: false,
        success_snackbar: false,
        error_message: '',
        success_message: '',
        comp_valid: true,
        required: [
          v => !!v || 'A mező kitöltése kötelező!'
        ],
        tax_code_rules: [
          value => !!(value) || 'A mező kitöltése kötelező!',
          value => /\d\d\d\d\d\d\d\d-\d-\d\d/.test(value) || 'Hibás adószám formátum!',
        ],
        preloader: false,
        del_comp_dialog: false,
        companies: [],
        search_comp: '',
        places: [],
        search_place: '',
        search_company: '',
        comp_address: '',
        comp_id: '',
        comp_name: '',
        comp_tax: '',
        new_company_dialog: false,
        new_place_dialog: false,
        place_id: '',
        place_nev: '',
        place_address: '',
        place_valid: true,
        del_place_dialog: false,
        new_worker_dialog: false,
        worker_id: '',
        worker_nev: '',
        munkaora: '',
        szabadsag: '',
        worker_place: '',
        worker_comp: '',
        worker_valid: true,
        del_worker_dialog: false,
        munkaszunet: '',
        end_time: '',
        end_time_menu: false,
        start_time: '',
        start_time_menu: false,
        timeRules: [
          v => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Hibás idő formátum!',
        ],
        email: '',
        rules: {
          required: value => !!value || 'Kötelező mező',
          w_email: value => (!value || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) || 'Hibás adószám formátum!',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Hibás e-mail cím formátum!'
          },
        },
        mail_valid: true,
        comp_email: '',
        szabadsagCalc_dialog: false,
        birthdate: '',
        numOfChilds: '',
        numOfHcChilds: '',
        age_alert: '',
        banyasz: false,
        fogyi: false,
        worker_email: '',
        selected_draft: [],
        drafts: [],
        selected_draft_for_worker: '',
        disable_auto_schedule: false,
        send_dialog: false,
        kotetlen: false,
    }),
    methods: {
      getJWT: async function() {
        var jwt = "";
        await auth.currentUser.getIdToken().then(function(idToken) {
          jwt = idToken;
        }).catch(function(error) {
          // Handle error
          console.log(error);
        });
        return jwt;
      },
      getWorkers: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/workersForDatas', {
        token: jwt
        })
        .then((response) => {
          this.customers = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getPlaces: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/placesForDatas', {
        token: jwt
        })
        .then((response) => {
          this.places = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getCompanies: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/companies', {
        token: jwt
        })
        .then((response) => {
          this.companies = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      saveCompany: async function () {
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/saveCompany', {
        token: jwt,
        comp_address: this.comp_address,
        comp_id: this.comp_id,
        comp_name: this.comp_name,
        comp_tax: this.comp_tax,
        comp_email: this.comp_email,
        })
        .then((response) => {
          this.closeCompDialog()
          this.preloader = false;
          this.getCompanies()
          this.success_message = response.data
          this.success_snackbar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      savePlace: async function () {
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/savePlace', {
        token: jwt,
        place_address: this.place_address,
        place_id: this.place_id,
        place_nev: this.place_nev
        })
        .then((response) => {
          this.closePlaceDialog()
          this.preloader = false;
          this.getPlaces()
          this.refreshPlaces()
          this.success_message = response.data
          this.success_snackbar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      saveWorker: async function () {
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/saveWorker', {
        token: jwt,
        worker_nev: this.worker_nev,
        worker_id: this.worker_id,
        worker_place: this.worker_place,
        munkaora: this.munkaora,
        worker_comp: this.worker_comp,
        start_time: this.start_time,
        end_time: this.end_time,
        munkaszunet: this.munkaszunet,
        szabadsag: this.szabadsag,
        worker_email: this.worker_email,
        draft_id: this.selected_draft_for_worker,
        disable_auto_schedule: this.disable_auto_schedule,
        kotetlen: this.kotetlen,
        })
        .then((response) => {
          this.closeWorkerDialog()
          this.preloader = false;
          this.getWorkers()
          this.refreshWorkers()
          this.refreshWorkersonDashboard()
          this.refreshWorkersonSettings()
          this.refreshDocWorkers()
          this.success_message = response.data
          this.success_snackbar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      delCompany: async function () {
        this.del_comp_dialog = false
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/delCompany', {
        token: jwt,
        comp_id: this.comp_id
        })
        .then((response) => {
          this.closeCompDialog()
          this.preloader = false;
          this.getCompanies()
          if (response.data['success'] == true) {
            this.success_message = response.data['message'];
            this.success_snackbar = true;
          } else if (response.data['success'] == false) {
            this.error_message = response.data['message'];
            this.error_snackbar = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      delPlace: async function () {
        this.del_place_dialog = false
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/delPlace', {
        token: jwt,
        place_id: this.place_id,
        })
        .then((response) => {
          this.closePlaceDialog()
          this.preloader = false;
          this.getPlaces()
          this.refreshPlaces()
          this.success_message = response.data
          this.success_snackbar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      delWorker: async function () {
        this.del_worker_dialog = false
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/delWorker', {
        token: jwt,
        worker_id: this.worker_id
        })
        .then((response) => {
          this.closeWorkerDialog()
          this.preloader = false;
          this.getWorkers()
          this.refreshWorkers()
          this.refreshWorkersonDashboard()
          this.refreshDocWorkers()
          this.success_message = response.data
          this.success_snackbar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getDrafts: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/drafts', {
        token: jwt
        })
        .then((response) => {
          this.drafts = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getCompany(value) {
        this.comp_address = value.comp_address;
        this.comp_id = value.comp_id;
        this.comp_name = value.comp_name;
        this.comp_tax = value.comp_tax;
        this.comp_email = value.comp_email;
        this.new_company_dialog = true;
      },
      getPlace(value) {
        this.place_nev = value.rovid_nev;
        this.place_id = value.place_id;
        this.place_address = value.cim;
        this.new_place_dialog = true;
      },
      getWorker(value) {
        this.worker_nev = value.nev;
        this.worker_id = value.worker_id;
        this.worker_place = value.def_place;
        this.munkaora = value.munkaora;
        this.worker_comp = value.company;
        this.start_time = value.def_start;
        this.end_time = value.def_end;
        this.munkaszunet = value.rest;
        this.szabadsag = value.szabadsag;
        this.worker_email = value.worker_email;
        this.disable_auto_schedule = value.disable_autosave;
        this.kotetlen = value.kotetlen;
        this.selected_draft_for_worker = value.draft_id;
        this.new_worker_dialog = true;
      },
      closeCompDialog() {
        this.comp_address = '';
        this.comp_id = '';
        this.comp_name = '';
        this.comp_tax = '';
        this.comp_email = '';
        this.new_company_dialog = false
        this.preloader = false;
      },
      closePlaceDialog() {
        this.place_nev = '';
        this.place_id = '';
        this.place_address = '';
        this.new_place_dialog = false
        this.preloader = false;
      },
      closeWorkerDialog() {
        this.worker_nev = '';
        this.worker_id = '';
        this.worker_place = '';
        this.munkaora = '',
        this.worker_comp = '',
        this.start_time = '',
        this.end_time = '',
        this.munkaszunet = '',
        this.szabadsag = '';
        this.munkaora = '',
        this.worker_email = '',
        this.birthdate = '',
        this.numOfChilds = '',
        this.numOfHcChilds = '',
        this.age_alert = '',
        this.banyasz = false,
        this.fogyi = false,
        this.new_worker_dialog = false,
        this.disable_auto_schedule = false,
        this.selected_draft_for_worker = '',
        this.preloader = false;
      },
      newCompany() {
        this.new_company_dialog = true;
        this.resetValidation();
      },
      newPlace() {
        this.new_place_dialog = true;
        this.resetPalceValidation();
      },
      newWorker() {
        this.new_worker_dialog = true;
        this.resetWorkerValidation();
      },
      goToSchedule: async function () {
        await this.$router.push({ path: '/beosztas' });
      },
      resetValidation () {
        this.$refs.company_form.resetValidation()
      },
      resetPalceValidation () {
        this.$refs.place_form.resetValidation()
      },
      resetWorkerValidation () {
        this.$refs.worker_form.resetValidation()
      },
      refreshWorkers() {
        EventBus.$emit('refreshWorkers')
      },
      refreshWorkersonDashboard() {
        EventBus.$emit('refreshWorkersonDashboard')
      },
      refreshWorkersonSettings() {
        EventBus.$emit('refreshWorkersonSettings')
      },
      refreshDocWorkers() {
        EventBus.$emit('refreshDocWorkers')
      },
      refreshPlaces() {
        EventBus.$emit('refreshPlaces')
      },
      hourDiff() {
        let self = this

        this.selected_draft.forEach(function(element, index) {
          var date = new Date().toLocaleDateString('en-US');
          var timeStart = new Date(date + " " + element.start_time).getTime();
          var timeEnd = new Date(date + " " + element.end_time).getTime();

          var hourDiff = timeEnd - timeStart;
          hourDiff = Math.round(hourDiff / 60000);
          hourDiff = hourDiff/60;

          if (hourDiff < 0) {
              hourDiff = 24 + hourDiff;
           }

           if (hourDiff > 6 && hourDiff <= 9 && element.rest < 20) {
             self.selected_draft[index]['rest'] = 20;
           }
           if (hourDiff > 9 && element.rest < 45) {
             self.selected_draft[index]['rest'] = 45;
           }
           if (hourDiff <= 6) {
             self.selected_draft[index]['rest'] = '';
           }

        })
     },
     munkaOra() {
       var date = new Date().toLocaleDateString('en-US');
       var timeStart = new Date(date + " " + this.start_time).getTime();
       var timeEnd = new Date(date + " " + this.end_time).getTime();

       var hourDiff = timeEnd - timeStart;
       hourDiff = Math.round(hourDiff / 60000);
       var munkaIdo = hourDiff/60;

       if (this.start_time && this.end_time) {
         if (this.munkaszunet) {
           this.munkaora = munkaIdo - this.munkaszunet/60;
         } else {
           this.munkaora = munkaIdo;
         }
         this.munkaora = Math.round(this.munkaora * 10) / 10;
         if (this.munkaora < 0) {
           this.munkaora = 24 + this.munkaora;
           this.munkaora = Math.round(this.munkaora * 10) / 10;
         }
       }
     }
    },
    mounted() {
      this.getWorkers()
      this.getPlaces()
      this.getCompanies()
      this.getDrafts()
    },
    watch: {
      numOfHcChilds(value) {
        if ((value && !this.numOfChilds) || (value > this.numOfChilds)) {
          this.numOfChilds = value;
        }
      },
      birthdate(value) {
        if (/^(19|20)\d{2}/.test(value)) {
        var year = new Date().getFullYear();
        var diff = year - value;
        if (diff < 15) {
          this.age_alert = "15 év alatti gyermek nem foglalkoztatható!";
        } else {
          this.age_alert = null;
        }
      }
    },
    },
    created() {
      EventBus.$on('refreshDraftWorkers', () => {
            this.getDrafts();
      })
    },
    computed: {
      calc_szabadsag() {
        var year = new Date().getFullYear();
        if (/^(19|20)\d{2}/.test(this.birthdate)) {
          var diff = year - this.birthdate;
        } else {
          diff = 0;
        }

        if (diff >= 25 && diff < 28) {
          var pot = 1;
        } else if (diff >= 28 && diff < 31) {
          pot = 2;
        } else if (diff >= 31 && diff < 33) {
          pot = 3;
        } else if (diff >= 33 && diff < 35) {
          pot = 4;
        } else if (diff >= 35 && diff < 37) {
          pot = 5;
        } else if (diff >= 37 && diff < 39) {
          pot = 6;
        } else if (diff >= 39 && diff < 41) {
          pot = 7;
        } else if (diff >= 41 && diff < 43) {
          pot = 8;
        } else if (diff >= 43 && diff < 45) {
          pot = 9;
        } else if (diff >= 45) {
          pot = 10;
        } else if (diff >= 15 && diff < 18) {
          pot = 5;
        } else {
          pot = 0;
        }

        if (this.numOfChilds == 1) {
          var gyerekPot = 2;
        } else if (this.numOfChilds == 2) {
          gyerekPot = 4;
        } else if (this.numOfChilds >= 3) {
          gyerekPot = 7;
        } else {
          gyerekPot = 0;
        }

        if (this.numOfHcChilds != 0) {
          var fogyPot = this.numOfHcChilds * 2;
        } else {
          fogyPot = 0;
        }

        if (this.banyasz) {
          var banyasz = 5;
        } else {
          banyasz = 0;
        }

        if (this.fogyi) {
          var fogyi = 5;
        } else {
          fogyi = 0;
        }

        var alapszabi = 20;
        var szabi = alapszabi + pot + gyerekPot + fogyPot + banyasz + fogyi;

        return szabi;
      },
    },
  }
</script>

<style lang="css">
</style>
