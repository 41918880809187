import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import hu from 'vuetify/lib/locale/hu'

export default new Vuetify({
  lang: {
  locales: { hu },
  current: 'hu',
  },
});
