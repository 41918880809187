import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDP3HBgiTdYigvfTJiVvXjyjlmPb7r-Ktw",
  authDomain: "munkaido-ca9c3.firebaseapp.com",
  projectId: "munkaido-ca9c3",
  storageBucket: "munkaido-ca9c3.appspot.com",
  messagingSenderId: "571056979595",
  appId: "1:571056979595:web:83de92bbdf36429ea5de8e",
  measurementId: "G-7YEGH7ELFS"
};

const firebase = initializeApp(firebaseConfig);

export default firebase;
