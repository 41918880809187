<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
    <router-link to="/">
      <div class="d-flex align-center">
        <v-img
          class="shrink mr-2"
          contain
          src="./assets/schedule.png"
          transition="scale-transition"
          width="60"
        />

      </div>
    </router-link>

      <v-spacer></v-spacer>

      <v-toolbar-title><b>Munkaidő nyilvántartás</b></v-toolbar-title>

      <v-spacer></v-spacer>

      <div class="hidden-lg-and-down">
        <v-btn v-if="user" text to="/">Irányítópult<v-icon right>dashboard</v-icon></v-btn>
        <v-btn v-if="user" text to="/beosztas">Beosztások<v-icon right>edit_calendar</v-icon></v-btn>
        <v-btn v-if="user" text to="/kimutatas">Kimutatások<v-icon right>leaderboard</v-icon></v-btn>
        <v-btn v-if="user" text to="/adatok">Törzsadatok<v-icon right>engineering</v-icon></v-btn>
        <v-btn v-if="user" text to="/beallitas">Beállítások<v-icon right>settings</v-icon></v-btn>
        <v-btn text to="/login">Felhasználó<v-icon right>power_settings_new</v-icon></v-btn>
        <v-btn icon to="/kapcsolat"><v-icon>email</v-icon></v-btn>
      </div>

      <div class="d-xl-none">
        <v-btn text @click.stop="drawer = !drawer">
          <v-icon>menu</v-icon>
        </v-btn>
      </div>

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      right
      app

    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Főmenü</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>

        <v-list-item
          link
          to="/"
          v-if="user"
        >
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Irányítópult</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/beosztas"
          v-if="user"
        >
          <v-list-item-icon>
            <v-icon>edit_calendar</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Beosztások</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/kimutatas"
          v-if="user"
        >
          <v-list-item-icon>
            <v-icon>leaderboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Kimutatások</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/adatok"
          v-if="user"
        >
          <v-list-item-icon>
            <v-icon>engineering</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Törzsadatok</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/beallitas"
          v-if="user"
        >
          <v-list-item-icon>
            <v-icon>settings</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Beállítások</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/login"
        >
          <v-list-item-icon>
            <v-icon>power_settings_new</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Felhasználó</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          to="/kapcsolat"
        >
          <v-list-item-icon>
            <v-icon>email</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Kapcsolat</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
      <br><br>
    </v-main>

      <v-footer fixed color="red" v-if="anonym && footer" height="35">
        <v-container>
        <v-row>
        <v-col
          class="text-center white--text pt-0"
          cols="11"
        >
          <router-link to="/login" class="white--text">Véglegesítse regisztrációját!</router-link>
        </v-col>
        <v-col
          cols="1"
          class="pt-0"
        >
        <v-btn
          icon
          small
          color="white"
          @click="footer = false"
          >
          <v-icon>close</v-icon>
          </v-btn>
        </v-col>
        </v-row>
        </v-container>
      </v-footer>

  </v-app>
</template>

<script>
import EventBus from '@/EventBus';
import firebase from '@/firebaseinit';
import { getAuth, onAuthStateChanged} from "firebase/auth";
const auth = getAuth(firebase);

export default {
  name: 'App',

  components: {
  },

  data: () => ({
      drawer: null,
      user: '',
      anonym: '',
      footer: true
  }),
  methods: {
    refreshCustomers() {
      EventBus.$emit('refreshCustomers')
    },
    User: function () {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          this.user = user.uid;
          this.anonym = user.isAnonymous;
        } else {
          console.log('Nincs bejelentkezett felhasználó!');
          this.user = null;
        }
      });
    }
  },
  mounted() {
    this.User()
  },
  created() {
    EventBus.$on('refreshUserState', () => {
          this.User();
    })
  }
};
</script>

<style>

</style>
