<template lang="html">
  <div class="contact-component">
    <br>
    <v-container>
<v-row>
  <v-col cols="12" md="12">
      <v-card
        class="mx-auto"
        max-width="800"
      >

        <v-card-title>
          Küldje el, miben segíthetünk?
        </v-card-title>
        <v-container>
          <v-form
            ref="contact_form"
            v-model="contact_valid"
          >
        <v-row>
          <v-col
            cols="12"
          >
            <v-text-field
              outlined
              v-model="contact_name"
              label="Kapcsolattartó"
              :rules="required"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              outlined
              v-model="contact_company"
              label="Cégnév"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              outlined
              v-model="contact_email"
              label="E-mail címe"
              :rules="emailRules"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              outlined
              v-model="contact_phone"
              label="Telefonszám"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-textarea
              v-model="contact_message"
              outlined
              name="input-7-4"
              label="Üzenet"
              :rules="required"
            ></v-textarea>
          </v-col>
          <v-col cols="12" v-if="!user">
           <v-checkbox
             v-model="adatkezeles"
             :rules="required_accept"
           >
           <template v-slot:label>
             <div>
               A küldéssel elfogadom az
                   <a
                     target="_blank"
                     href="https://munkaora.hu/Munkaora_GDPR.pdf"
                     @click.stop
                   >
                     Adatkezelési tájékoztatóban
                   </a>
                 foglaltakat!
               </div>
             </template>
           </v-checkbox>
           </v-col>
        </v-row>
        </v-form>
        </v-container>
        <v-card-actions>

          <v-spacer></v-spacer>

          <v-btn
            outlined
            color="red"
            :disabled="!contact_valid || mail_loader"
            :loading="mail_loader"
            @click="Send()"
          >
            Küldés
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-col>
</v-row>

    </v-container>

    <v-snackbar
      v-model="error_snackbar"
      color="red"
    >
      {{ error_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="error_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="success_snackbar"
    >
      {{ success_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="success_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import firebase from '@/firebaseinit';
import { getAuth, onAuthStateChanged} from "firebase/auth";
const auth = getAuth(firebase);

  export default {
    data: () => ({
      contact_valid: true,
      required: [
        v => !!v || 'A mező kitöltése kötelező!'
      ],
      required_accept: [
        v => !!v || 'A tájékoztató elfogadása kötelező!'
      ],
      emailRules: [
        v => !!v || 'A mező kitöltése kötelező!',
        v => /.+@.+\..+/.test(v) || 'Az e-mail cím formátuma nem megfelelő!',
      ],
      recaptcha_token: '',
      contact_name: '',
      contact_company: '',
      contact_email: '',
      contact_phone: '',
      contact_message: '',
      mail_loader: false,
      error_snackbar: false,
      error_message: '',
      success_message: '',
      success_snackbar: false,
      adatkezeles: false,
      user: '',
    }),
    methods: {
      async recaptcha() {
        await this.$recaptchaLoaded()
        this.recaptcha_token = await this.$recaptcha('send')
      },
      Send: async function() {
        this.mail_loader = true;
        await this.recaptcha()
        this.$http.post('/recaptcha', {
        token: this.recaptcha_token
        })
        .then((response) => {
          if (response.data['success'] == 'true') {
            this.$http.post('/functions/send_contractForm', {
            contact_name: this.contact_name,
            contact_company: this.contact_company,
            contact_email: this.contact_email,
            contact_phone: this.contact_phone,
            contact_message: this.contact_message
            })
            .then((response) => {
              this.mail_loader = false;
              if (response.data['success'] == true) {
                this.success_message = response.data['message'];
                this.success_snackbar = true;
                this.formReset();
              } else {
                this.mail_loader = false;
                this.error_message = response.data['message'];
                this.error_snackbar = true;
              }
            })
            .catch(function (error) {
              console.log(error);
            });
          }
          console.log(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      formReset () {
        this.$refs.contact_form.reset()
      },
      User: function () {
        onAuthStateChanged(auth, (user) => {
          if (user) {
            this.user = user.uid;
            this.contact_email = user.email;
          } else {
            console.log('Nincs bejelentkezett felhasználó!');
            this.user = null;
          }
        });
      }
    },
    watch: {

    },
    mounted() {
      this.User()
    }
  }
</script>

<style lang="css">
</style>
