<template lang="html">
  <div class="munkaido-component">
   <v-container>
     <v-row justify="center">
      <center>
      <v-col cols="12">
      <v-btn-toggle
        v-model="type"
        rounded
        color="primary"
      >
        <v-btn value="month">
          Hónap
        </v-btn>
        <v-btn value="week" class="hidden-xs-only">
          Hét
        </v-btn>
        <v-btn value="day">
          Nap
        </v-btn>
        <v-btn value="4day" class="hidden-xs-only">
          4 nap
        </v-btn>
        <v-btn value="category">
          Munkahely
        </v-btn>
      </v-btn-toggle>
      </v-col>
    </center>
    </v-row>
    <v-row justify="center">
        <v-btn class="mt-3" fab text @click="$refs.calendar.prev()">
        <v-icon>keyboard_double_arrow_left</v-icon>
        </v-btn>
      <v-col cols="8" md="4">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="date_text"
            label="Hónap választása"
            dense
            outlined
            readonly
            v-on="on"
            @click:append="today = new Date().toISOString().slice(0,10)"
          >
          <template v-slot:append>
            <v-btn color="primary" outlined fab small class="mb-2" text @click="today = new Date().toISOString().slice(0,10)">
            MA
            </v-btn>
          </template>
          </v-text-field>
        </template>
        <v-date-picker v-model="today" @input="menu = false" first-day-of-week="1" show-adjacent-months>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="today = new Date().toISOString().slice(0,10)">Mai nap</v-btn>
        </v-date-picker>
      </v-menu>
      </v-col>
        <v-btn class="mt-3" fab text @click="$refs.calendar.next()">
        <v-icon>keyboard_double_arrow_right</v-icon>
        </v-btn>
    </v-row>
    <v-row justify="center" class="mt-0">
      <v-btn
      v-if="workers != ''"
        color="green"
        depressed
        dark
        @click="newSchedule()"
      >
        Új beosztás
        <v-icon
          right
          dark
        >
          add_circle_outline
        </v-icon>
      </v-btn>
      <v-alert
        v-if="workers == ''"
        border="left"
        colored-border
        type="error"
        outlined
        class="text-body-2 text-justify"
      >
        Beosztás készítéséhez először rögzíteni kell legalább egy céget, munkahelyet és dolgozót!
        <br><br>
        <v-btn
          small
          outlined
          color="primary"
          @click="$router.push({ path: '/adatok' })"
        >
          Ugrás a törzsadatokhoz
        </v-btn>
      </v-alert>
    </v-row>
    <br>
    <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header disable-icon-rotate>Szűrők
        <template v-slot:actions>
          <v-icon>
            filter_alt
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
        <v-col cols="12" md="3">
          <v-select
            v-model="place_filter"
            :items="places"
            item-text="rovid_nev"
            item-value="place_id"
            label="Munkahely"
            prepend-icon="apartment"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="3">
          <v-autocomplete
            v-model="worker_filter"
            :items="workers"
            item-text="nev"
            item-value="worker_id"
            label="Dolgozó"
            prepend-icon="face"
            outlined
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            v-model="tipus_filter"
            :items="event_types_select"
            item-text="name"
            item-value="short_name"
            label="Munkaidő típusa"
            prepend-icon="list"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            class="mt-3"
            outlined
            color="red"
            @click="place_filter = '', worker_filter = '', tipus_filter = ''"
          >
            Töröl
          </v-btn>
        </v-col>
        </v-row>
    </v-expansion-panel-content>
    </v-expansion-panel>
    </v-expansion-panels>
    <br>
    <v-progress-linear
      :active="calendar_loader"
      indeterminate
      color="red"
    ></v-progress-linear>
    <v-sheet height="750">
    <v-calendar
      v-model="today"
      ref="calendar"
      color="primary"
      :now="now"
      :type="type"
      :events="events"
      :weekdays="[1, 2, 3, 4, 5, 6, 0]"
      :categories="categories"
      @click:date="type = 'day'"
      @click:more="viewDay"
      @click:day="Day($event)"
      @click:time="Time($event)"
      @click:event="onEvent($event)"
      :event-color="getEventColor"
      :event-text-color="getEventTextColor"
    >
      <template v-slot:event="{event}">
        <div v-if="event.start_time">{{ event.name }} ({{event.start_time}} - {{event.end_time}})<br>Munkaóra: {{event.munkaido}}<br>Típus: {{event.type_name}}<br>{{event.rovid_nev}}</div>
        <!-- <div v-if="event.tipus == 'holiday'">{{ event.name }} (szabadság)<br>Munkaóra: {{event.munkaido}}<br>{{event.rovid_nev}}</div> -->
        <!-- <div v-if="event.tipus == 'sick'">{{ event.name }} (betegállomány)<br>Munkaóra: {{event.munkaido}}<br>{{event.rovid_nev}}</div> -->
        <div v-if="event.munkaido_tipus == 'free'">{{ event.name }} ({{event.type_name}})<br>Munkaóra: {{event.munkaido}}<br>{{event.rovid_nev}}</div>
        <div v-if="event.tipus == 'unnep' || event.tipus == 'munkanap'">{{ event.name }}</div>
        <div v-if="!event.start_time && (event.tipus != 'unnep' || event.tipus != 'munkanap' || event.tipus != 'holiday' || event.tipus != 'sick')">{{ event.name }}</div>
      </template>
    </v-calendar>
    </v-sheet>
   </v-container>

   <template>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        scrollable
        max-width="800px"
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>Munkaidő rögzítése</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="closeEvent()"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-col
              cols="12"
            >
          <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate class="blue--text">
              Tipp!
              <template v-slot:actions>
                <v-icon color="info">
                  lightbulb_circle
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2 text-justify">
              <br>
              Munkaidő rögzítése lehetséges csupán egy napra (alapértelmezett értékek),
              vagy bármekkora időintervallumra is (hét/hónap/negyedév, stb.).<br>
              <br>
              Amennyiben több napi (heti vagy akár havi) beosztást szeretne egyszerre rögzíteni, úgy a befejező dátum értékét kell ennek megfelelően beállítani.
              Ilyenkor két további opció válik elérhetővé:<br><br>
              <ol>
                <li><b>Rögzítésből kihagyott napok:</b> amikor nagyobb időszakot rögzítünk egyszerre, lehetőségünk van olyan napokat kiválasztani, amiket figyelmen kívül szeretnénk hagyni a rögzítés során (pl. szabadnapok).</li>
                <li><b>Beosztás sablon használata:</b> a beállítások menüben előre létrehozott sablon alapján történik a rögzítés a kiválasztott időszakon belül (ezzel a módszerrel pl. lehetőség van az egyes napokon különböző munkaidő megadására).</li>
              </ol>
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
          </v-col>
            <v-container>
              <v-form
                ref="form"
                v-model="valid"
              >
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="worker"
                    :items="workers"
                    item-text="nev"
                    item-value="worker_id"
                    label="Dolgozó"
                    prepend-icon="face"
                    outlined
                    :rules="required"
                    item-disabled="disable"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-if="tipus.tipus != 'free' && tipus.short_name != 'standby'"
                    v-model="place"
                    :items="places"
                    item-text="rovid_nev"
                    item-value="place_id"
                    label="Munkahely"
                    prepend-icon="apartment"
                    outlined
                    :rules="required"
                    item-disabled="disable"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                <v-menu
                  ref="start_date_menu"
                  v-model="start_date_menu"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  :return-value.sync="start_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                  <template v-slot:activator="{ attrs }">
                    <v-text-field
                      v-model="start_date"
                      label="Kezdő dátum"
                      prepend-icon="today"
                      append-icon="date_range"
                      @click:append="start_date_menu = true"
                      outlined
                      v-bind="attrs"
                      :rules="dateRules"
                      v-mask="'####-##-##'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="start_date"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    show-adjacent-months
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="start_date_menu = false"
                    >
                      Mégsem
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_date_menu.save(start_date)"
                    >
                      Kiválaszt
                    </v-btn>
                  </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                <v-menu
                  ref="end_date_menu"
                  v-model="end_date_menu"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  :return-value.sync="end_date"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  >
                  <template v-slot:activator="{ attrs }">
                    <v-text-field
                      v-model="end_date"
                      label="Befejező dátum"
                      prepend-icon="mdi-calendar"
                      append-icon="date_range"
                      @click:append="end_date_menu = true"
                      outlined
                      v-bind="attrs"
                      :rules="dateRules"
                      v-mask="'####-##-##'"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="end_date"
                    no-title
                    scrollable
                    first-day-of-week="1"
                    show-adjacent-months
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="end_date_menu = false"
                    >
                      Mégsem
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.end_date_menu.save(end_date)"
                    >
                      Kiválaszt
                    </v-btn>
                  </v-date-picker>
                  </v-menu>
                </v-col>

                  <v-col cols="12" md="6" v-if="!event_id && date_diff >=2">
                    <v-menu
                      ref="free_dates_menu"
                      v-model="free_dates_menu"
                      :close-on-content-click="false"
                      :return-value.sync="free_dates"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-combobox
                          v-model="free_dates"
                          multiple
                          chips
                          small-chips
                          label="Rögzítésből kihagyott napok"
                          readonly
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="event_busy"
                          append-icon="date_range"
                          @click:append="free_dates_menu = true"
                          persistent-hint
                        ></v-combobox>
                      </template>
                      <v-date-picker
                        v-model="free_dates"
                        multiple
                        no-title
                        scrollable
                        first-day-of-week="1"
                        show-adjacent-months
                        :picker-date.sync="pickerDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="free_dates_menu = false"
                        >
                          Mégsem
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.free_dates_menu.save(free_dates)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12" md="6" v-if="!event_id && date_diff >=2">
                    <v-select
                      v-model="selected_draft"
                      :items="drafts"
                      item-text="draft_name"
                      item-value="draft_id"
                      label="Beosztás sablon"
                      prepend-icon="drive_file_rename_outline"
                      outlined
                      clearable
                      append-icon=""
                    ></v-select>
                  </v-col>

                <v-col cols="12" md="6" v-if="tipus.tipus != 'free' && !selected_draft">
                  <v-menu
                    ref="start_time_menu"
                    v-model="start_time_menu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :nudge-right="40"
                    :return-value.sync="start_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                  <template v-slot:activator="{ attrs }">
                  <v-text-field
                    v-model="start_time"
                    label="Munkaidő kezdete"
                    prepend-icon="hourglass_top"
                    append-icon="access_time_filled"
                    @click:append="start_time_menu = true"
                    v-bind="attrs"
                    outlined
                    :rules="timeRules"
                    v-mask="'##:##'"
                  >
                  </v-text-field>
                  </template>
                    <v-time-picker
                      v-if="start_time_menu"
                      v-model="start_time"
                      full-width
                      format="24hr"
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="start_time_menu = false"
                    >
                      Mégsem
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.start_time_menu.save(start_time)"
                    >
                      Kiválaszt
                    </v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6" v-if="tipus.tipus != 'free' && !selected_draft">
                  <v-menu
                    ref="end_time_menu"
                    v-model="end_time_menu"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :nudge-right="40"
                    :return-value.sync="end_time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ attrs }">
                      <v-text-field
                        v-model="end_time"
                        label="Munkaidő vége"
                        prepend-icon="hourglass_bottom"
                        append-icon="access_time_filled"
                        @click:append="end_time_menu = true"
                        outlined
                        v-bind="attrs"
                        :rules="timeRules"
                        v-mask="'##:##'"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="end_time_menu"
                      v-model="end_time"
                      full-width
                      format="24hr"
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="end_time_menu = false"
                    >
                      Mégsem
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.end_time_menu.save(end_time)"
                    >
                      Kiválaszt
                    </v-btn>
                    </v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6" v-if="tipus.tipus != 'free' && !selected_draft">
                  <v-text-field
                    v-model="munkaora"
                    label="Munkaidő (óra)"
                    outlined
                    readonly
                    prepend-icon="schedule"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" v-if="tipus.tipus != 'free' && !selected_draft">
                  <v-text-field
                    v-model="munkaszunet"
                    label="Munkaközi szünet (perc)"
                    outlined
                    required
                    v-mask="'##'"
                    prepend-icon="running_with_errors"
                    :hint="munkaora_alert"
                    persistent-hint
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="tipus"
                    :items="event_types_select"
                    item-text="name"
                    return-object
                    label="Munkaidő típusa"
                    prepend-icon="list"
                    outlined
                    :rules="required"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-textarea
                    v-model="comment"
                    label="Megjegyzés"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn
              v-if="event_id"
              color="red"
              outlined
              @click="del_dialog = true"
            >
              Töröl
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="green"
              outlined
              @click="saveSchedule()"
              :disabled="save_button || !valid"
              :loading="save_button"
            >
              Rögzít
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>

    <v-speed-dial
      v-model="fab"
      dark
      fixed
      bottom
      right
      style="bottom: 50px;"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="pink"
          dark
          fab
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            list
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="indigo"
        @click="newSchedule()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="green"
        @click="getSchedule()"
      >
        <v-icon>refresh</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="red"
        @click="place_filter = '', worker_filter = '', tipus_filter = ''"
      >
        <v-icon>filter_alt_off</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="grey"
        @click.stop="$refs.calendar.next()"
      >
        <v-icon>keyboard_double_arrow_right</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="grey"
        @click.stop="$refs.calendar.prev()"
      >
        <v-icon>keyboard_double_arrow_left</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-dialog
      v-model="del_dialog"
      persistent
      width="400"
    >
      <v-card>
        <v-card-text class="pt-5 text-subtitle-1">
          Biztosan törli a napi munkaidőt?
          <br><br>
          <v-expansion-panels focusable>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate class="blue--text">
              Tipp!
              <template v-slot:actions>
                <v-icon color="info">
                  lightbulb_circle
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              Amennyiben több beosztást szeretne egyszerre törölni, azt könnyedén megteheti a <router-link to="/kimutatas">"Kimutatások"</router-link> menüben, az adott időszakra vonatkozó beosztások lekérdezését követően!
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            :disabled="del_loading"
            @click="del_dialog = false"
          >
            Nem
          </v-btn>
          <v-btn
            color="green"
            text
            :disabled="del_loading"
            :loading="del_loading"
            @click="delSchedule()"
          >
            Igen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="response_dialog"
      width="500"
      scrollable
    >

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Rögzítés eredménye
        </v-card-title>
        <br>
        <v-card-text class="text-body-2">
          <br>
          <ul>
            <li v-for="item in success_response" :key="item.index">
              {{ item }}
            </li>
          </ul>
          <br>
          <v-alert
            border="top"
            colored-border
            type="error"
            outlined
            class="font-weight-light text-body-2"
          >
          Figyelmeztetések:
          <ul>
            <li v-for="item in error_response" :key="item.index">
              {{ item }}
            </li>
          </ul>
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="response_dialog = false"
          >
            Bezár
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="help_dialog"
      transition="dialog-top-transition"
      width="600"
    >
        <v-card>
          <v-toolbar
            color="green"
            dark
          >
          <v-toolbar-title>Súgó</v-toolbar-title>
          <v-btn
            icon
            dark
          >
            <v-icon>lightbulb</v-icon>
          </v-btn>
        </v-toolbar>
          <v-card-text>
            <br>
            Munkaidő rögzítése lehetséges csupán egy napra (alapértelmezett értékek),
            vagy bármekkora időintervallumra is.<br>
            <br>
            Amennyiben több napi (heti vagy akár havi) beosztást szeretne egyszerre rögzíteni, úgy a befejező dátum értékét kell ennek megfelelően beállítani.
            Ilyenkor két további opció válik elérhetővé:<br><br>
            <ol>
              <li><b>Rögzítésből kihagyott napok:</b> amikor nagyobb időszakot rögzítünk egyszerre, lehetőségünk van olyan napokat kiválasztani, amiket figyelmen kívül szeretnénk hagyni a rögzítés során (pl. szabadnapok).</li>
              <li><b>Beosztás sablon használata:</b> a beállítások menüben előre létrehozott sablon alapján történik a rögzítés a kiválasztott időszakon belül (ezzel a módszerrel pl. lehetőség van az egyes napokon különböző munkaidő megadására).</li>
            </ol>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="help_dialog = false"
            >bezár</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar
      v-model="success_snackbar"
    >
      {{ success_text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="success_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import EventBus from '@/EventBus';
import firebase from '@/firebaseinit';
import { getAuth } from "firebase/auth";
const auth = getAuth(firebase);

  export default {
    data: () => ({
      menu: false,
      today: new Date().toISOString().slice(0,10),
      now: null,
      interval: null,
      //now: new Date().toISOString().slice(0,10),
      type: 'month',
      dialog: false,
      categories: [],
      events: [],
      event_id: '',
      workers: [],
      places: [],
      tipus: '',
      worker: '',
      place: '',
      start_date: '',
      start_date_menu: false,
      end_date: '',
      end_date_menu: false,
      start_time: '',
      start_time_menu: false,
      end_time: '',
      end_time_menu: false,
      munkaszunet: '',
      jwt: '',
      save_button: false,
      place_filter: '',
      worker_filter: '',
      tipus_filter: '',
      munkaora: '',
      valid: true,
      required: [
        v => !!v || 'Kötelező mező!',
      ],
      dateRules: [
        v => !!v || 'Kötelező mező!',
        v => /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(v) || 'Hibás dátum formátum!',
      ],
      timeRules: [
        v => !!v || 'Kötelező mező!',
        v => /^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(v) || 'Hibás idő formátum!',
      ],
      response_dialog: false,
      success_response: [],
      error_response: [],
      success_text: '',
      error_text: '',
      success_snackbar: false,
      del_dialog: false,
      del_loading: false,
      comment: '',
      free_dates: [],
      free_dates_menu: false,
      pickerDate: null,
      calendar_loader: false,
      event_types_all: [],
      event_types_select: [],
      drafts: [],
      selected_draft: '',
      help_dialog: false,
      fab: false,
    }),
    methods: {
      getJWT: async function() {
        var jwt = "";
        await auth.currentUser.getIdToken().then(function(idToken) {
          jwt = idToken;
        }).catch(function(error) {
          // Handle error
          console.log(error);
        });
        return jwt;
      },
      getWorkers: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/workers', {
        token: jwt
        })
        .then((response) => {
          this.workers = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getAllEventTypes: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/event_types_all', {
        token: jwt
        })
        .then((response) => {
          this.event_types_all = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getSelectEventTypes: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/event_types_select', {
        token: jwt
        })
        .then((response) => {
          this.event_types_select = response.data;
          this.tipus = response.data[0];
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getWorker: async function () {
        this.start_time = '';
        this.end_time = '';
        this.munkaszunet = '';
        this.munkaora = '';
        this.place = '';
        this.resetValidation();
        const jwt = await this.getJWT();
        this.$http.post('/datas/worker', {
        token: jwt,
        worker: this.worker
        })
        .then((response) => {
          this.start_time = response.data[0]['def_start'];
          this.end_time = response.data[0]['def_end'];
          this.munkaszunet = response.data[0]['rest'];
          this.place = response.data[0]['def_place'];
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getPlaces: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/places', {
        token: jwt
        })
        .then((response) => {
          this.places = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getCategories: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/categories', {
        token: jwt
        })
        .then((response) => {
          this.categories = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getSchedule: async function () {
        //this.now = new Date().toISOString().slice(0,10);
        this.calendar_loader = true;
        this.events = [];
        const jwt = await this.getJWT();
        this.$http.post('/functions/getSchedule_220128', {
        token: jwt,
        worker_filter: this.worker_filter,
        place_filter: this.place_filter,
        tipus_filter: this.tipus_filter,
        date: this.today
        })
        .then((response) => {
          this.calendar_loader = false;
          this.events = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      saveSchedule: async function () {
        this.save_button = true;
        const jwt = await this.getJWT();
        this.$http.post('/functions/saveSchedule220412', {
        token: jwt,
        worker: this.worker,
        place: this.place,
        start_date: this.start_date,
        end_date: this.end_date,
        start_time: this.start_time,
        end_time: this.end_time,
        tipus: this.tipus,
        event_id: this.event_id,
        munkaszunet: this.munkaszunet,
        comment: this.comment,
        free_dates: this.free_dates,
        selected_draft: this.selected_draft
        })
        .then((response) => {
          this.save_button = false;
          this.success_response = response.data['success'];
          this.error_response = response.data['error'];
          if (this.error_response != '') {
            this.response_dialog = true;
          } else {
            this.success_text = "Sikeres rögzítés!";
            this.success_snackbar = true;
          }
          console.log(response.data);
          this.getSchedule()
          this.closeEvent();
          this.refreshDashboard()
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      delSchedule: async function () {
        this.del_loading = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/delSchedule_220202', {
        token: jwt,
        event_id: this.event_id
        })
        .then((response) => {
          this.del_loading = false;
          this.del_dialog = false;
          this.success_response = response.data['success'];
          this.error_response = response.data['error'];
          if (this.error_response != '') {
            this.response_dialog = true;
          } else {
            this.success_text = "Sikeres törlés!";
            this.success_snackbar = true;
          }
          this.getSchedule()
          console.log(response.data);
          this.closeEvent();
          this.refreshDashboard();
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getDrafts: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/drafts', {
        token: jwt
        })
        .then((response) => {
          this.drafts = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      viewDay ({ date }) {
        this.today = date
        this.type = 'day'
      },
      Day(value) {
        //console.log('Day klikk!');
        if (this.tipus.uid != 'hidden') {
          this.dialog = true
        } else {
          this.closeEvent()
        }
        if (!this.event_id) {
        this.start_date = value.date
        this.end_date = value.date
          if (this.place_filter) {
            this.place = this.place_filter
          }
          if (this.worker_filter) {
            this.worker = this.worker_filter
          }
        }
      },
      Time(value) {
        if (this.tipus.uid != 'hidden') {
          this.dialog = true
        } else {
          this.closeEvent()
        }
        if (!this.event_id) {
        var split = value.time.split(':');
        var split_hour = parseInt(split[0]);
        var hour = split[0] + ":00";
        console.log(hour);
        if (split_hour < 23) {
          var next_hour = split_hour + 1;
          if (next_hour < 10) {
            var next_hour_text = "0"+next_hour+":00";
          } else {
            next_hour_text = next_hour+":00";
          }
          this.end_date = value.date;
        } else {
          next_hour = "00:00";
          var current_date = new Date(value.date)
          // Add a day
          current_date.setDate(current_date.getDate() + 1)
          this.end_date = current_date.toISOString().slice(0,10)
        }
        this.start_date = value.date,
        this.start_time = hour,
        this.end_time = next_hour_text
        if (this.place_filter) {
          this.place = this.place_filter
        }
        if (this.worker_filter) {
          this.worker = this.worker_filter
        }
       }
       //console.log(this.start_time + ' ' + this.end_time);
      },
      onEvent(value) {
        var selected_tipus;
        this.worker = value.event.dolgozo,
        this.place = value.event.hely,
        this.start_date = value.event.start_date,
        this.end_date = value.event.end_date,
        this.start_time = value.event.start_time,
        this.end_time = value.event.end_time,
        this.event_types_all.forEach(function(element) {
          if (value.event.tipus == element.short_name) {
            selected_tipus = element;
          }
        })
        this.tipus = selected_tipus,
        this.munkaszunet = value.event.szunet,
        this.event_id = value.event.sc_id,
        this.comment = value.event.comment;
        //console.log('Event klikk!');
        //console.log(value);
      },
      newSchedule() {
        this.start_date = this.now
        this.end_date = this.now
        this.dialog = true
      },
      closeEvent() {
        this.worker = '',
        this.place = '',
        this.start_date = '',
        this.end_date = '',
        this.start_time = '',
        this.end_time = '',
        this.tipus = this.event_types_all[0],
        this.event_id = '',
        this.munkaszunet = '',
        this.munkaora = '',
        this.comment = '';
        this.selected_draft = '';
        this.free_dates = [];
        this.pickerDate = null;
        this.dialog = false,
        this.save_button = false
        this.resetValidation()
      },
      getEventColor (event) {
        var color;
        this.event_types_all.forEach(function(element) {
          if (event.tipus == element.short_name) {
            color = element.color;
          }
        })
        return color;
      },
      getEventTextColor (event) {
        var color;
        this.event_types_all.forEach(function(element) {
          if (event.tipus == element.short_name) {
            color = element.text_color;
          }
        })
        return color;
      },
      breakpoint() {
        console.log(this.$vuetify.breakpoint.name);
      },
      hourDiff() {
        var start_date = new Date(this.start_date).toLocaleDateString('en-US');
        var timeStart = new Date(start_date + " " + this.start_time).getTime();
        var timeEnd = new Date(start_date + " " + this.end_time).getTime();

        var hourDiff = timeEnd - timeStart;
        hourDiff = Math.round(hourDiff / 60000);
        hourDiff = hourDiff/60;

        if (hourDiff < 0) {
            hourDiff = 24 + hourDiff;
         }

        if (hourDiff > 6 && hourDiff <= 9 && this.munkaszunet < 20) {
          this.munkaszunet = 20;
        }
        if (hourDiff > 9 && this.munkaszunet < 45) {
          this.munkaszunet = 45;
        }
        if (hourDiff <= 6) {
          this.munkaszunet = '';
        }

        if (this.start_time && this.end_time) {
          if (this.munkaszunet) {
            this.munkaora = hourDiff - this.munkaszunet/60;
          } else {
            this.munkaora = hourDiff;
          }
          this.munkaora = Math.round(this.munkaora * 10) / 10;
          if (this.munkaora < 0) {
            this.munkaora = 24 + this.munkaora;
          }
        }
     },
     munkaOra() {
       var start_date = new Date(this.start_date).toLocaleDateString('en-US');
       var timeStart = new Date(start_date + " " + this.start_time).getTime();
       var timeEnd = new Date(start_date + " " + this.end_time).getTime();

       var hourDiff = timeEnd - timeStart;
       hourDiff = Math.round(hourDiff / 60000);
       var munkaIdo = hourDiff/60;

       if (this.start_time && this.end_time) {
         if (this.munkaszunet) {
           this.munkaora = munkaIdo - this.munkaszunet/60;
         } else {
           this.munkaora = munkaIdo;
         }
         this.munkaora = Math.round(this.munkaora * 10) / 10;
         if (this.munkaora < 0) {
           this.munkaora = 24 + this.munkaora;
           this.munkaora = Math.round(this.munkaora * 10) / 10;
         }
       }

     },
     resetValidation () {
      this.$refs.form.resetValidation()
     },
     refreshDashboard() {
       EventBus.$emit('refreshDashboard')
     },
    },
    mounted() {
      this.getWorkers()
      this.getPlaces()
      this.getCategories()
      //this.getSchedule()
      this.getAllEventTypes()
      this.getSelectEventTypes()
      this.getDrafts()
    },
    beforeDestroy() {
    // prevent memory leak
      clearInterval(this.interval)
    },
    watch: {
      start_date(value) {
        if (/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(value)) {
          if (value > this.end_date || this.event_id && this.start_time < this.end_time || (this.event_id && this.tipus == 'holiday' || this.tipus == 'sick') || (this.event_id && value < this.end_date && this.start_time < this.end_time)) {
            this.end_date = value
          }
          if (!this.event_id && value >= this.end_date && this.start_time > this.end_time) {
            this.start_time = '';
            this.end_time = '';
          }
          if (this.event_id && this.start_time > this.end_time) {
            var date = new Date(value);
            date.setDate(date.getDate() + 1);
            this.end_date = date.toISOString().slice(0,10);
          }
        }
      },
      end_date(value) {
        if (/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(value)) {
          if (value < this.start_date || this.event_id && this.start_time < this.end_time || (this.event_id && this.tipus == 'holiday' || this.tipus == 'sick') || (this.event_id && value > this.start_date && this.start_time < this.end_time)) {
            this.start_date = value
          }
          if (!this.event_id && value <= this.start_date && this.start_time > this.end_time) {
            this.start_time = '';
            this.end_time = '';
          }
          if (this.event_id && this.start_time > this.end_time) {
            var date = new Date(value);
            date.setDate(date.getDate() - 1);
            this.start_date = date.toISOString().slice(0,10);
          }
        }
      },
      start_time(value) {
        if (/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(value) && this.end_time) {
          if (value > this.end_time && this.start_date == this.end_date) {
            var date = new Date(this.start_date);
            date.setDate(date.getDate() + 1);
            this.end_date = date.toISOString().slice(0,10);
          }
          if (this.event_id && value < this.end_time && this.start_date < this.end_date) {
            this.start_date = this.end_date;
          }
          if (value) {
            this.hourDiff()
          }
        }
      },
      end_time(value) {
        if (/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(value) && this.start_time) {
          if (value < this.start_time && this.start_date == this.end_date) {
            var date = new Date(this.start_date);
            date.setDate(date.getDate() + 1);
            this.end_date = date.toISOString().slice(0,10);
          }
          if (this.event_id && value > this.start_time && this.start_date < this.end_date) {
            this.start_date = this.end_date;
          }
          if (value) {
            this.hourDiff()
          }
       }
      },
      worker_filter() {
        this.getSchedule()
        if (!this.event_id) {
          this.worker = this.worker_filter;
        }
      },
      place_filter() {
        this.getSchedule()
        if (!this.event_id) {
          this.place = this.place_filter;
        }
      },
      tipus_filter() {
        this.getSchedule()
      },
      munkaszunet() {
        this.munkaOra()
      },
      worker() {
        if (!this.event_id) {
          this.getWorker()
        }
      },
      pickerDate (newval,oldval) {
        if (!oldval) {
          this.pickerDate = this.start_date;
        }
      },
      date_text() {
        this.getSchedule();
      },
      now() {
        this.getSchedule();
      },
      tipus(value) {
        if (value.short_name == 'standby') {
          this.place = null;
        }
      }
    },
    created() {
      EventBus.$on('refreshWorkers', () => {
            this.getWorkers();
      }),
      EventBus.$on('refreshPlaces', () => {
            this.getPlaces();
      }),
      EventBus.$on('refreshSchedule', () => {
            this.getSchedule();
      }),
      EventBus.$on('refreshEventTypes', () => {
            this.getAllEventTypes();
      }),
      EventBus.$on('refreshSelectEventTypes', () => {
            this.getSelectEventTypes();
      }),
      EventBus.$on('refreshDraftWorkers', () => {
            this.getDrafts()
      }),
      EventBus.$on('goToWorkerSchedule', (datas) => {
          this.worker_filter = datas;
      }),
      this.interval = setInterval(() => {
        this.now = new Date().toISOString().slice(0,10);
      }, 1000)
    },
    computed: {
      date_text() {
        var options = { year: 'numeric', month: 'long'};
        var datum  = new Date(this.today);
        return datum.toLocaleDateString('hu-HU',options);
      },
      date_diff() {
        var diff = Math.abs(new Date(this.end_date) - new Date(this.start_date))/1000/60/60/24;
        return diff;
      },
      munkaora_alert() {
        if (this.munkaora > 6 && (!this.munkaszunet || this.munkaszunet < 20)) {
          var alert = "6 órát meghaladó munkavégzés esetén legalább 20 perc szünetet kell tartani!";
          return alert;
        } else {
          return "";
        }
      },
      types() {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return [
              {text: 'Hónap', value: 'month'},
              {text: 'Nap', value: 'day'},
              {text: 'Helyszín/nap', value: 'category'},
            ];
          default:
            return [
              {text: 'Hónap', value: 'month'},
              {text: 'Hét', value: 'week'},
              {text: 'Nap', value: 'day'},
              {text: '4 nap', value: '4day'},
              {text: 'Helyszín/nap', value: 'category'},
            ];
        }
      }
    }
  }
</script>

<style lang="css">
</style>
