<template lang="html">
  <div class="documents-component">
    <br>
    <v-container>
      <v-card>
      <v-container>
      <b>Válassza ki a lekérdezni kívánt időszakot és dolgozó(ka)t:</b>
      <br><br>
      <v-row>
        <v-col cols="12" md="4">
        <v-date-picker
         v-model="dates"
         full-width
         range
         first-day-of-week="1"
         :selected-items-text="selected_dates"
         elevation="1"
       >
       <v-spacer></v-spacer>
         <v-btn
           text
           color="red"
           @click="dates = [], beosztasok = [], selected_workers = []"
         >
           Törlés
         </v-btn>
         <v-btn
           text
           color="primary"
           @click="dates = [new Date().toISOString().slice(0,10)]"
         >
           Mai nap
         </v-btn>
       </v-date-picker>
       </v-col>
        <v-col cols="12" md="8">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Dolgozók választása
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  v-model="selected_workers"
                  :headers="[{text: 'Dolgozó neve', value: 'nev'}]"
                  :items="workers"
                  :items-per-page="5"
                  item-key="worker_id"
                  show-select
                ></v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Munkaidő típus választása
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  v-model="selected_event_types"
                  :headers="[{text: 'Elnevezés', value: 'name'}]"
                  :items="event_types_select"
                  :items-per-page="5"
                  item-key="type_id"
                  show-select
                ></v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
    </v-row>
    </v-container>
    <v-card-actions>
      <v-row>
        <v-col cols="12" md="4" xl="2" lg="3">
          <v-btn
            color="primary"
            outlined
            @click="getBeosztas()"
            :disabled="dates == ''"
          >
            Beosztások lekérdezése
            <v-icon
              right
              dark
            >
              sync
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="3" xl="2" lg="2">
          <v-btn
            color="green"
            outlined
            @click="Print()"
            :disabled="dates == ''"
          >
            Nyomtatás
            <v-icon
              right
              dark
            >
              print
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" md="2" xl="2" lg="2">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="green"
                outlined
                v-bind="attrs"
                v-on="on"
                :disabled="dates == ''"
              >
                Letöltés
                <v-icon
                  right
                  dark
                >
                  download
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    @click="Download()"
                  >
                    PDF formátum
                    <v-icon
                      right
                      dark
                    >
                      picture_as_pdf
                    </v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <v-btn
                    text
                    @click="DownloadExcel()"
                  >
                    Excel formátum
                    <v-icon
                      right
                      dark
                    >
                      grid_on
                    </v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" md="2" xl="2" lg="3">
          <v-btn
            color="primary"
            outlined
            @click="sendToAlldialog = true"
            :disabled="selected_workers == '' || dates == ''"
          >
            Küldés e-mailen
            <v-icon
              right
              dark
            >
              mail
            </v-icon>
          </v-btn>
        </v-col>
       </v-row>
      </v-card-actions>
    </v-card>
    <br>
    <div id="beosztas">
    <v-card v-show="beosztasok != ''">
      <v-card-title>
          Dolgozói beosztások
          <br>
          ({{dates[0]}} - {{dates[1]}})
      </v-card-title>

      <v-tabs
        v-model="tab"
        grow
        show-arrows
      >
        <v-tab
          v-for="(item, index) in beosztasok"
          :key="index"
        >
          {{ index }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(item, index) in beosztasok"
          :key="index"
        >
          <v-card
            color="basil"
            flat
          >
            <v-container>
              <p v-if="item['beosztas'][0].dolgozo_szabadsag">Kivett éves szabadság ({{item['beosztas'][0].year}}): {{item['beosztas'][0].eves_szabadsag}}/{{item['beosztas'][0].dolgozo_szabadsag}}</p>
              <p v-if="!item['beosztas'][0].dolgozo_szabadsag">Kivett éves szabadság ({{item['beosztas'][0].year}}): {{item['beosztas'][0].eves_szabadsag}}</p>
              <p>Össz. munkaóra: {{item['beosztas'][0].sum_munkaido}}</p>
              <v-expansion-panels v-if="item['error'] != ''" focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header class="red--text">
                    <b>Figyelmeztetések!</b>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ul>
                      <li v-for="item in item['error']" :key="item.index">
                        {{ item }}
                      </li>
                    </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-data-table
                v-model="selected_from_beosztas"
                :headers="beosztas_headers"
                :items="item['beosztas']"
                item-key="sc_id"
                show-select
              >
              </v-data-table>
              <v-row>
                <v-col cols="12" md="4" xl="2" lg="3">
                  <v-btn
                    color="red"
                    outlined
                    @click="del_dialog = true"
                    :disabled="selected_from_beosztas == ''"
                  >
                    Beosztások törlése
                    <v-icon
                      right
                      dark
                    >
                      delete
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    color="primary"
                    outlined
                    @click="send_dialog = true, worker_email = item['worker_email'], worker_to_send = {'worker_id': item['worker_id']}"
                  >
                    Küldés e-mailen
                    <v-icon
                      right
                      dark
                    >
                      mail
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    </div>

    </v-container>

    <v-dialog
      v-model="del_dialog"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-text class="pt-5 text-subtitle-1">
          Biztosan törli a kijelölt beosztásokat?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            :disabled="del_loading"
            @click="del_dialog = false"
          >
            Nem
          </v-btn>
          <v-btn
            color="green"
            text
            :disabled="del_loading"
            :loading="del_loading"
            @click="delBeosztas()"
          >
            Igen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="send_dialog"
      persistent
      width="400"
    >
    <v-form
       ref="mail_form"
       v-model="mail_valid"
     >
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          Beosztás küldése e-mailen
        </v-card-title>
        <v-card-text class="pt-5 text-subtitle-1">
          <v-text-field
            outlined
            v-model="worker_email"
            label="E-mail cím"
            append-icon="mail_outline"
            :rules="[rules.email]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="send_dialog = false, send_loading = false, worker_email = ''"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="green"
            text
            :disabled="send_loading || !mail_valid"
            :loading="send_loading"
            @click="sendBeosztas()"
          >
            Küld
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog
      v-model="sendToAlldialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          Beosztás küldése e-mailen
        </v-card-title>
        <v-card-text class="pt-5 text-subtitle-1">
          <v-form
             v-model="all_mail_valid"
           >
          <div v-if="!sendToOther">
          A beosztás az alábbi dolgozóknak lesz elküldve:
          <ul>
            <li v-for="item in selected_workers" :key="item.worker_id">
              <div v-if="item.worker_email">{{ item.nev }}: {{item.worker_email}}</div>
              <div v-if="!item.worker_email">{{ item.nev }}: <span style="color:red;">hiányzó e-mail cím!</span></div>
            </li>
          </ul>
          </div>
          <v-switch
            v-model="sendToOther"
            label="Küldés egy adott e-mail címre"
          ></v-switch>
          <div v-if="sendToOther">
            <p><i>(Ebben az esetben a kijelölt dolgozók beosztása csak erre az e-mail címre kerül elküldésre!)</i></p>
            <v-text-field
              outlined
              v-model="other_email"
              label="E-mail cím"
              append-icon="mail_outline"
              :rules="[rules.email]"
            ></v-text-field>
          </div>
        </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="sendToAlldialog = false, send_loading = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="green"
            text
            :disabled="send_loading || !all_mail_valid"
            :loading="send_loading"
            @click="sendToAll()"
          >
            Küld
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="error_snackbar"
      color="red"
    >
      {{error_message}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="error_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="success_snackbar"
    >
      <div v-html="success_message"></div>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="success_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import EventBus from '@/EventBus';
import firebase from '@/firebaseinit';
import { getAuth } from "firebase/auth";
const auth = getAuth(firebase);

  export default {
    data: () => ({
      error_snackbar :false,
      success_snackbar: false,
      error_message: '',
      success_message: '',
      dates: [],
      workers: [],
      selected_workers: [],
      beosztasok: [],
      tab: null,
      selected_from_beosztas: [],
      beosztas_headers: [
        { text: 'Kezdés', value: 'kezdes' },
        { text: 'Befejezés', value: 'befejezes' },
        { text: 'Munkaóra', value: 'munkaido' },
        { text: 'Helyszín', value: 'cim' },
        { text: 'Típus', value: 'm_tipus' },
      ],
      del_dialog: false,
      del_loading: false,
      send_dialog: false,
      worker_email: '',
      worker_to_send: [],
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Hibás e-mail cím formátum!'
        },
      },
      mail_valid: true,
      send_loading: false,
      sendToAlldialog: false,
      sendToOther: false,
      other_email: '',
      all_mail_valid: true,
      event_types_select: '',
      selected_event_types: [],
    }),
    methods: {
      getJWT: async function() {
        var jwt = "";
        await auth.currentUser.getIdToken().then(function(idToken) {
          jwt = idToken;
        }).catch(function(error) {
          // Handle error
          console.log(error);
        });
        return jwt;
      },
      getWorkers: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/workers', {
        token: jwt
        })
        .then((response) => {
          this.workers = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getBeosztas: async function () {
        //this.dates.sort();
        const jwt = await this.getJWT();
        this.$http.post('/functions/beosztas_220202', {
        token: jwt,
        dates: this.dates,
        selected_workers: this.selected_workers,
        selected_event_types: this.selected_event_types
        })
        .then((response) => {
          this.beosztasok = response.data;
          this.$nextTick(function () {
            var element = document.getElementById("beosztas");
            element.scrollIntoView({ behavior: 'smooth', block: 'start'});
          })
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      delBeosztas: async function () {
        this.del_loading = true;
        const jwt = await this.getJWT();
        this.$http.post('/functions/delMultipleSchedule', {
        token: jwt,
        selected_from_beosztas: this.selected_from_beosztas
        })
        .then((response) => {
          this.del_dialog = false;
          this.del_loading = false;
          this.selected_from_beosztas = [];
          this.success_message = response.data;
          this.success_snackbar = true;
          this.getBeosztas()
          this.refreshSchedule()
          this.refreshDashboard()
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getSelectEventTypes: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/event_types_select', {
        token: jwt
        })
        .then((response) => {
          this.event_types_select = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      Print: async function() {
        //this.dates.sort();
        const jwt = await this.getJWT();
        this.print_loader = true;
        var windowReference = window.open();
        this.$http.post('/documents/beosztas_220426', {
        token: jwt,
        dates: this.dates,
        selected_workers: this.selected_workers,
        selected_event_types: this.selected_event_types,
        attachment: false,
        }, {responseType: 'blob'})
        .then((response) => {
          this.print_loader = false;
          const file = new Blob(
          [response.data],
          {type: 'application/pdf'});
          const fileURL = URL.createObjectURL(file);
          //window.open(fileURL);
          windowReference.location = fileURL;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      Download: async function() {
        const jwt = await this.getJWT();
        this.print_loader = true;
        this.$http.post('/documents/beosztas_220426', {
        token: jwt,
        dates: this.dates,
        selected_workers: this.selected_workers,
        selected_event_types: this.selected_event_types,
        attachment: false,
        }, {responseType: 'blob'})
        .then((response) => {
          this.print_loader = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Munkaidő_beosztás_'+this.dates[0]+'-'+this.dates[1]+'.pdf');
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      DownloadExcel: async function() {
        const jwt = await this.getJWT();
        this.print_loader = true;
        this.$http.post('/documents/beosztas_excel', {
        token: jwt,
        dates: this.dates,
        selected_workers: this.selected_workers,
        selected_event_types: this.selected_event_types,
        attachment: false,
        }, {responseType: 'blob'})
        .then((response) => {
          this.print_loader = false;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Munkaidő_beosztás_'+this.dates[0]+'-'+this.dates[1]+'.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      sendBeosztas: async function () {
        this.send_loading = true;
        const jwt = await this.getJWT();
        this.$http.post('/documents/beosztas_220426', {
        token: jwt,
        selected_workers: [this.worker_to_send],
        selected_event_types: this.selected_event_types,
        worker_email: this.worker_email,
        dates: this.dates,
        attachment: true,
        })
        .then((response) => {
          this.worker_email = ''
          this.send_loading = false;
          this.send_dialog = false;
          this.success_message = response.data;
          this.success_snackbar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      sendToAll: async function () {
        this.send_loading = true;
        const jwt = await this.getJWT();
        this.$http.post('/documents/beosztas_220426', {
        token: jwt,
        selected_workers: this.selected_workers,
        selected_event_types: this.selected_event_types,
        worker_email: this.worker_email,
        dates: this.dates,
        attachment: true,
        sendToOther: this.sendToOther,
        other_email: this.other_email
        })
        .then((response) => {
          this.send_loading = false;
          this.sendToAlldialog = false;
          this.sendToOther = false;
          this.other_email = '';
          this.success_message = response.data;
          this.success_snackbar = true;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      refreshSchedule() {
        EventBus.$emit('refreshSchedule')
      },
      refreshDashboard() {
        EventBus.$emit('refreshDashboard')
      },
    },
    watch: {
      dates(value) {
        if (value[0] > value[1]) {
          this.dates.sort();
          console.log(value);
        }
      },
      tab() {
        this.selected_from_beosztas = [];
      },
    },
    mounted() {
      this.getWorkers()
      this.getSelectEventTypes()
    },
    created() {
      EventBus.$on('refreshDocWorkers', () => {
            this.getWorkers();
      }),
      EventBus.$on('refreshDocEventTypes', () => {
            this.getSelectEventTypes();
      })
    },
    computed: {
      selected_dates() {
        return this.dates[0]+"-től "+this.dates[1]+"-ig";
      }
    },
  }
</script>

<style lang="css">
</style>
