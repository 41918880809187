import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Settings from '../views/Settings.vue'
import Documents from '../views/Documents.vue'
import Schedule from '../views/Schedule.vue'
import Contact from '../views/Contact.vue'
import Adatok from '../views/Adatok.vue'
import firebase from '@/firebaseinit.js'
import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth(firebase);

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/beosztas',
    name: 'Schedule',
    component: Schedule
  },
  {
    path: '/beallitas',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/adatok',
    name: 'Adatok',
    component: Adatok
  },
  {
    path: '/kimutatas',
    name: 'Documents',
    component: Documents
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/kapcsolat',
    name: 'Contact',
    component: Contact
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  await onAuthStateChanged(auth, (user) => {
    if (to.path !== '/kapcsolat' && (!user && to.path !== '/login')) next('login')
    //if (!user && to.path !== '/login') next('login')
    else next()
  });
})

export default router
