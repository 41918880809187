<template lang="html">
  <div class="customer-component">

   <v-container>

     <v-row class="mt-8">
     <v-col cols="12">
       <v-card
         outlined
         shaped
         color="orange lighten-5"
       >
         <v-list-item>
           <v-list-item-content class="headline font-weight-medium">
               Beállítások
           </v-list-item-content>
         </v-list-item>
           <v-container>

    <v-expansion-panels focusable popout>
      <v-expansion-panel>
        <v-expansion-panel-header class="title font-weight-regular" disable-icon-rotate>
          Beosztás sablonok
          <template v-slot:actions>
            <v-icon>
              drive_file_rename_outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <br>
          <v-expansion-panels focusable popout>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate class="blue--text">
              Súgó
              <template v-slot:actions>
                <v-icon color="info">
                  help_outline
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2 text-justify">
              Beosztás sablon létrehozásával lehetősége van naponta eltérő kezdő és befejező időpontokat beállítani, így amikor egyszerre több napi/heti/havi beosztást rögzít, nem kell az általánostól eltérő munkaidőket utólag külön-külön módosítani a beosztásokban.
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
          <br>
          <v-card outlined>
            <v-card-text>
              <v-form
                ref="drafts_form"
                v-model="drafts_valid"
              >
            <v-row>
            <v-col cols="8" md="6">
              <v-combobox
                v-model="selected_draft_id"
                :items="drafts"
                item-text="draft_name"
                item-value="draft_id"
                label="Sablon neve"
                outlined
                :rules="required"
              ></v-combobox>
            </v-col>
            <v-col cols="4" md="4">
              <v-btn
                class="mt-2"
                color="green"
                outlined
                @click="selected_draft_id = '', $refs.drafts_form.resetValidation()"
              >
                Új
                <v-icon
                  right
                  dark
                >
                  add
                </v-icon>
              </v-btn>
            </v-col>
            </v-row>
            <v-row v-for="(days, index) in selected_draft" v-bind:key="index">
              <v-col
                cols="12"
                md="3"
              >
                <v-select
                  v-model="days.day_no"
                  @change="disable_days()"
                  :items="dows"
                  label="Hét napja"
                  prepend-inner-icon="today"
                  outlined
                  :rules="required"
                  item-disabled="disable"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-menu
                  ref="start_time_menu"
                  v-model="df_start_time_menu[index]"
                  :close-on-content-click="false"
                  :close-on-click="true"
                  :nudge-right="40"
                  :return-value.sync="days.start_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="days.start_time"
                  label="Munkaidő kezdete"
                  prepend-inner-icon="hourglass_top"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  readonly
                  :rules="df_timeRules"
                  v-mask="'##:##'"
                  @change="hourDiff()"
                >
                </v-text-field>
                </template>
                  <v-time-picker
                    v-if="df_start_time_menu[index]"
                    v-model="days.start_time"
                    full-width
                    format="24hr"
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.start_time_menu[index].save(days.start_time)"
                  >
                    Kiválaszt
                  </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" md="3">
                <v-menu
                  ref="end_time_menu"
                  v-model="df_end_time_menu[index]"
                  :close-on-content-click="false"
                  :close-on-click="true"
                  :nudge-right="40"
                  :return-value.sync="days.end_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="days.end_time"
                      label="Munkaidő vége"
                      prepend-inner-icon="hourglass_bottom"
                      outlined
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="df_timeRules"
                      v-mask="'##:##'"
                      @change="hourDiff()"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="df_end_time_menu[index]"
                    v-model="days.end_time"
                    full-width
                    format="24hr"
                  >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.end_time_menu[index].save(days.end_time)"
                  >
                    Kiválaszt
                  </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  v-model="days.rest"
                  label="Szünet (perc)"
                  outlined
                  v-mask="'##'"
                  prepend-inner-icon="running_with_errors"
                  hint="Munkaközi szünet"
                  persistent-hint
                ></v-text-field>
              </v-col>
              <v-col
                 cols="4"
                 lg="1"
                 md="1"
                 class="mt-2"
               >
                 <v-btn
                   small
                   outlined
                   fab
                   color="red"
                   @click="removeDraft(index, days.id)"
                 >
                   <v-icon>delete</v-icon>
                 </v-btn>
               </v-col>
            </v-row>
            <br>
            <v-btn
              color="green"
              outlined
              @click="addDraft()"
              :disabled="!selected_draft_id"
            >
              Hozzáad
              <v-icon
                right
                dark
              >
                add
              </v-icon>
            </v-btn>
            </v-form>
           </v-card-text>
          </v-card>
          <br>
          <v-row>
          <v-col cols="6" md="2">
          <v-btn
            color="red"
            outlined
            :disabled="!drafts_valid"
            @click="saveDraft()"
          >
            Mentés
            <v-icon
              right
              dark
            >
              save
            </v-icon>
          </v-btn>
          </v-col>
          <v-col cols="6" md="2">
          <v-btn
            v-if="draft_id_to_del != ''"
            color="red"
            outlined
            @click="del_draft_dialog = true"
          >
            Törlés
            <v-icon
              right
              dark
            >
              delete
            </v-icon>
          </v-btn>
          </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="title font-weight-regular" disable-icon-rotate>
          Egyedi munkaidő típusok
          <template v-slot:actions>
            <v-icon>
              list
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <br>
          <v-expansion-panels focusable popout>
          <v-expansion-panel>
            <v-expansion-panel-header disable-icon-rotate class="blue--text">
              Súgó
              <template v-slot:actions>
                <v-icon color="info">
                  help_outline
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2 text-justify">
              A rendszerben alapértelmezetten (a jogszabályi előírásoknak megfelelően) 6 féle munkaidő-típus van rögzítve: rendes munkaidő, rendkívüli munkaidő, készenlét, ügyelet, szabadság, betegszabadság. Bizonyos esetekben azonban a munkáltatónak szüksége lehet ezen kívül olyan egyéb beosztás típusokra is, ami az adott munkahely sajátosságaihoz igazodik.
              Ebben a menüpontban lehetősége van ilyen egyéni munkaidő-típusok létrehozására!
            </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
          <br>
          <v-card outlined>
            <v-card-text>
              <v-form
                ref="event_form"
                v-model="event_valid"
              >
              <v-row>
              <v-col cols="12" md="4">
                <v-combobox
                  v-model="selected_event_type"
                  :items="event_types_select"
                  item-text="name"
                  item-value="type_id"
                  label="Elnevezés"
                  outlined
                  :rules="required"
                ></v-combobox>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="event_code"
                  v-mask="'NNN'"
                  label="Munkaidő kódja"
                  hint="Munkaügyi dokumentumokon jelenik meg."
                  outlined
                  :rules="required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="event_type"
                  :items="event_types"
                  label="Munkanap fajtája"
                  outlined
                  :rules="required"
                  persistent-hint
                  hint='A "Szabadnap" típus egész napra szól, "Munkanap" típusnál állítható a kezdés és befejezés ideje.'
                ></v-select>
              </v-col>
              </v-row>
              </v-form>
              <v-row>
                <v-col cols="12" md="4">
                  Esemény háttérszíne
                  <v-color-picker
                    v-model="event_color"
                      dot-size="25"
                      hide-canvas
                      hide-inputs
                      show-swatches
                      swatches-max-height="80"
                    ></v-color-picker>
                </v-col>
                <v-col cols="12" md="4">
                  Esemény betűszíne
                  <v-color-picker
                    v-model="event_text_color"
                      dot-size="25"
                      hide-canvas
                      hide-inputs
                      show-swatches
                      swatches-max-height="80"
                    ></v-color-picker>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-switch
                    inset
                    v-model="hidden_event"
                    label="Nem jelenik meg a munkaügyi dokumentumokon."
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-switch
                    inset
                    v-model="allow_duplicate"
                    label="Duplikált rögzítés engedélyezése."
                    hint="Alapértelmezetten, hogyha a dolgozónak már van egy adott napon és időszakban rögzített munkaideje, a rendszer nem engedi, hogy még egy beosztás-típus rögzíthető legyen ugyanabban az időszakban. Ezzel az opcióval ez a tiltás felülírható!"
                    persistent-hint
                  ></v-switch>
                </v-col>
              </v-row>
           </v-card-text>
            <v-card-actions>
              <v-btn
                color="red"
                outlined
                @click="saveEventTypes()"
                :disabled="!event_valid"
              >
                Mentés
                <v-icon
                  right
                  dark
                >
                  save
                </v-icon>
              </v-btn>
              <v-btn
                v-if="custom_event_type_id"
                color="red"
                outlined
                @click="del_eventtype_dialog = true"
              >
                Törlés
                <v-icon
                  right
                  dark
                >
                  delete
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
     <v-expansion-panel>
       <v-expansion-panel-header class="title font-weight-regular" disable-icon-rotate>
         Automatizmusok
         <template v-slot:actions>
           <v-icon>
             autorenew
           </v-icon>
         </template>
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         <br>
         <v-expansion-panels focusable popout>
         <v-expansion-panel>
           <v-expansion-panel-header disable-icon-rotate class="blue--text">
             Súgó
             <template v-slot:actions>
               <v-icon color="info">
                 help_outline
               </v-icon>
             </template>
           </v-expansion-panel-header>
           <v-expansion-panel-content class="text-body-2 text-justify">
             <p>
               A munkaügyi előírások kötelezővé teszik, hogy legalább 1 héttel korábban közölni kell a dolgozó munkaidő beosztását.
               Ez a beállítás lehetővé teszi, hogy amennyiben a beosztás nem készülne el határidőben, úgy a vonatkozó szabályoknak megfelelően a rendszer automatikusan létrehozza azt.</p>
               <p>Az automatikus rögzítésre kétféle megoldás áll rendelkezése:</p>
               <ol>
                 <li>Beosztás sablon használatával: ebben az esetben csak az adott dolgozóhoz hozzárendelt beosztás-sablon szerinti napokon és munkaidőben történik a rögzítés.</li>
                 <li>Sablon használata nélkül: ilyenkor mindig az éppen aktuális beosztást másolja a rendszer a következő hét adott napjára (Figyelem! Amennyiben az aktuális napon éppen nincs beosztva a dolgozó, úgy a következő hétre sem fog neki beosztást készíteni!)</li>
               </ol>
           </v-expansion-panel-content>
         </v-expansion-panel>
         </v-expansion-panels>
         <br>
         <v-card outlined>
           <v-card-text>
           <v-switch
             inset
             v-model="auto_save"
             label="Automatikus munkaidő rögzítés 1 hétre előre"
           ></v-switch>
          </v-card-text>
           <v-card-actions>
             <v-btn
               color="red"
               outlined
               @click="saveAutoSettings()"
             >
               Mentés
               <v-icon
                 right
                 dark
               >
                 save
               </v-icon>
             </v-btn>
           </v-card-actions>
         </v-card>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel>
       <v-expansion-panel-header class="title font-weight-regular" disable-icon-rotate>
         Ellenőrző funkciók
         <template v-slot:actions>
           <v-icon>
             error_outline
           </v-icon>
         </template>
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         <br>
         <v-card outlined>
           <v-card-text>
           <v-switch
             inset
             v-model="save_notif"
             label="Munkajogi szabályok ellenőrzése rögzítéskor és törléskor"
           ></v-switch>
          </v-card-text>
         </v-card>
         <br>
         <v-card outlined>
           <v-card-text>
           <v-switch
             inset
             v-model="sunday_notif"
             label="Vasárnapi, ill. ünnepi munkavégzésre vonatkozó figyelmeztetések"
           ></v-switch>
          </v-card-text>
         </v-card>
         <br>
         <v-card outlined>
           <v-card-text>
            <v-row>
            <v-col cols="12">
           <v-switch
             inset
             v-model="weekly_rest"
             label="Heti pihenőidő ellenőrzése."
             hint="(Heti 5 munkanap + 2 pihenőnapos rendszer alkalmazása esetén hasznos.)"
             persistent-hint
           ></v-switch>
           </v-col>
          </v-row>
          <v-row>
          <v-col cols="12">
           <v-switch
             inset
             v-model="avg_rest"
             label="Átlagos heti pihenőidő ellenőrzése."
             hint="(Munkaidőkeret alkalmazása esetén hasznos, amikor nem teljesül minden héten az 5 munkanap + 2 pihenőnap beosztása.)"
             persistent-hint
           ></v-switch>
         </v-col>
        </v-row>
          </v-card-text>
         </v-card>
         <br>
         <v-form
            ref="mail_form"
            v-model="mail_valid"
          >
         <v-card outlined>
           <v-card-text>
           <v-switch
             inset
             v-model="mail_notif"
             label="Heti ellenőrző e-mail."
           ></v-switch>
           <v-col
              cols="12"
              md="4"
              v-if="mail_notif"
            >
              <v-text-field
                outlined
                v-model="email"
                :rules="[rules.required, rules.email]"
                label="E-mail"
                append-icon="mail_outline"
              ></v-text-field>
            </v-col>
          </v-card-text>
         </v-card>
         </v-form>
         <br>
         <v-btn
           color="red"
           outlined
           @click="saveAutoSettings()"
           :disabled="!mail_valid"
         >
           Mentés
           <v-icon
             right
             dark
           >
             save
           </v-icon>
         </v-btn>
       </v-expansion-panel-content>
     </v-expansion-panel>
     <v-expansion-panel>
       <v-expansion-panel-header class="title font-weight-regular" disable-icon-rotate>
         Megosztások
         <template v-slot:actions>
           <v-icon>
             share
           </v-icon>
         </template>
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         <br>
         <v-expansion-panels focusable popout>
         <v-expansion-panel>
           <v-expansion-panel-header disable-icon-rotate class="blue--text">
             Súgó
             <template v-slot:actions>
               <v-icon color="info">
                 help_outline
               </v-icon>
             </template>
           </v-expansion-panel-header>
           <v-expansion-panel-content class="text-body-2 text-justify">
             A funkció használatával lehetősége van létrehozni olyan internetes hivatkozásokat, melyeket megnyitva a dolgozók (vagy akivel a linket megosztotta) meg tudják nézni az éppen aktuális munkaidő beosztást, így nem szükséges azt mindig kinyomtatni, vagy e-mailen újraküldeni, amennyiben változás történne a beosztásokban!
           </v-expansion-panel-content>
         </v-expansion-panel>
         </v-expansion-panels>
         <br>
         <v-card outlined>
           <v-card-text>
           <v-switch
             @change="saveSharingSettings()"
             inset
             v-model="sharing"
             label="Munkaidő beosztás megosztása linken keresztül"
           ></v-switch>
           <p v-if="sharing">(Alapértelmezetten az aktuális hónap munkaidő beosztását mutatja minden dolgozóra vonatkozóan.)</p>
           <v-row>
           <v-col cols="12" md="4" lg="3">
           <v-btn
             v-if="sharing"
             color="green"
             outlined
             @click="copyToClipboard()"
           >
             Hivatkozás másolása
             <v-icon
               right
               dark
             >
               content_copy
             </v-icon>
           </v-btn>
           </v-col>
           <v-col cols="12" md="4" lg="3">
           <v-btn
             v-if="sharing"
             color="primary"
             outlined
             @click="send_dialog = true, hashToShare = sharing_url"
           >
             Hivatkozás küldése
             <v-icon
               right
               dark
             >
               mail
             </v-icon>
           </v-btn>
          </v-col>
           <v-col cols="12" md="4" lg="2">
           <v-btn
             v-if="sharing"
             color="red"
             outlined
             @click="refresh_dialog = true"
           >
             Hivatkozás frissítése
             <v-icon
               right
               dark
             >
               refresh
             </v-icon>
           </v-btn>
          </v-col>
          </v-row>
           <v-switch
             inset
             v-if="sharing"
             v-model="custom_sharing"
             label="Eltérő időszak meghatározása"
           ></v-switch>
           <v-row v-if="sharing && custom_sharing">
           <v-col cols="12" md="6">
             <v-text-field
               v-model="start_sharing"
               label="Kezdő időszak"
               outlined
               v-mask="'##'"
               persistent-hint
               hint="Megosztásban megjelenő napok száma az aktuális dátumtól visszafelé."
             ></v-text-field>
           </v-col>
           <v-col cols="12" md="6">
             <v-text-field
               v-model="end_sharing"
               label="Befejező időszak"
               outlined
               v-mask="'##'"
               persistent-hint
               hint="Megosztásban megjelenő napok száma az aktuális dátum után."
             ></v-text-field>
             <p style="color:red;" v-if="custom_sharing && end_sharing && end_sharing < 7">Legalább 7 napra előre meg kell adni a dolgozó munkaidő beosztását!</p>
           </v-col>
           </v-row>
           <v-switch
             inset
             v-if="sharing"
             v-model="workersSharing"
             label="Dolgozónkénti megosztás"
           ></v-switch>
           <p v-if="sharing" class="text-justify">(Amennyiben nem szeretné összes dolgozója beosztását elérhetővé tenni, itt külön-külön is létrehozhat az egyes személyekhez tartozó egyedi hivatkozást.)</p>
           <div v-if="sharing && workersSharing">
           <v-row v-for="(workers, index) in selected_workers_toShare" v-bind:key="index">
             <v-col
               cols="12"
               lg="3"
               md="3"
             >
               <v-autocomplete
                 v-model="workers.worker_id"
                 :items="customers"
                 item-text="nev"
                 item-value="worker_id"
                 label="Dolgozó"
                 append-icon="face"
                 outlined
               ></v-autocomplete>
             </v-col>
             <v-col cols="12" md="3" lg="2">
               <v-text-field
                 v-model="workers.start"
                 label="Kezdő időszak"
                 outlined
                 v-mask="'##'"
                 persistent-hint
                 hint="Megosztásban megjelenő napok száma az aktuális dátumtól visszafelé."
               ></v-text-field>
             </v-col>
             <v-col cols="12" md="3" lg="2">
               <v-text-field
                 v-model="workers.end"
                 label="Befejező időszak"
                 outlined
                 v-mask="'##'"
                 persistent-hint
                 hint="Megosztásban megjelenő napok száma az aktuális dátum után."
               ></v-text-field>
               <p style="color:red; font-size: 12px;" v-if="workersSharing && workers.end && workers.end < 7">Legalább 7 napra előre meg kell adni a dolgozó munkaidő beosztását!</p>
             </v-col>
             <v-col
                cols="3"
                lg="1"
                md="1"
                class="mt-2"
              >
                <v-btn
                  small
                  outlined
                  fab
                  color="red"
                  @click="removeWorkerToShare(index, workers.worker_id)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-col>
              <v-col
                 v-if="workers.hash"
                 cols="3"
                 lg="1"
                 md="1"
                 class="mt-2"
               >
               <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    fab
                    color="green"
                    @click="copyWorkerToClipboard(workers.hash)"
                  >
                    <v-icon>content_copy</v-icon>
                  </v-btn>
                </template>
                <span>Hivatkozás másolása</span>
              </v-tooltip>
              </v-col>
              <v-col
                 v-if="workers.hash"
                 cols="3"
                 lg="1"
                 md="1"
                 class="mt-2"
               >
               <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    fab
                    color="primary"
                    @click="send_dialog = true, hashToShare = workers.hash"
                  >
                    <v-icon>mail</v-icon>
                  </v-btn>
                </template>
                <span>Hivatkozás küldése</span>
              </v-tooltip>
              </v-col>
              <v-col
                 v-if="workers.hash"
                 cols="3"
                 lg="1"
                 md="1"
                 class="mt-2"
               >
               <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    outlined
                    fab
                    color="red"
                    @click="refresh_dialog = true, widToHash = workers.worker_id"
                  >
                    <v-icon>refresh</v-icon>
                  </v-btn>
                </template>
                <span>Hivatkozás frissítése</span>
              </v-tooltip>
              </v-col>
           </v-row>
           <br>
           <v-btn
             color="green"
             outlined
             @click="addWorkerToShare()"
           >
             Hozzáad
             <v-icon
               right
               dark
             >
               add
             </v-icon>
           </v-btn>
          </div>
          </v-card-text>
         </v-card>
         <br>
         <v-btn
           color="red"
           outlined
           @click="saveSharingSettings()"
         >
           Mentés
           <v-icon
             right
             dark
           >
             save
           </v-icon>
         </v-btn>
       </v-expansion-panel-content>
     </v-expansion-panel>

     <v-expansion-panel>
       <v-expansion-panel-header class="title font-weight-regular" disable-icon-rotate>
         Nyomtatás
         <template v-slot:actions>
           <v-icon>
             print
           </v-icon>
         </template>
       </v-expansion-panel-header>
       <v-expansion-panel-content>
         <br>
         <v-card outlined>
           <v-card-text>
           <v-switch
             inset
             v-model="coloring_pages"
             label="Munkaidő-típusok színének megjelenítése a nyomtatásban."
           ></v-switch>
          </v-card-text>
           <v-card-actions>
             <v-btn
               color="red"
               outlined
               @click="saveAutoSettings()"
             >
               Mentés
               <v-icon
                 right
                 dark
               >
                 save
               </v-icon>
             </v-btn>
           </v-card-actions>
         </v-card>
       </v-expansion-panel-content>
     </v-expansion-panel>

   </v-expansion-panels>

 </v-container>
</v-card>
</v-col>
</v-row>

   </v-container>

  <v-dialog
    v-model="del_eventtype_dialog"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-text class="pt-5 text-subtitle-1">
        Biztosan törölni szeretné a munkaidő típust?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="del_eventtype_dialog = false"
        >
          Nem
        </v-btn>
        <v-btn
          color="green"
          text
          @click="delEventTypes()"
          :disabled="del_eventtype_loading"
          :loading="del_eventtype_loading"
        >
          Igen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="del_draft_dialog"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-text class="pt-5 text-subtitle-1">
        Biztosan törölni szeretné a beosztás sablont?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          text
          @click="del_draft_dialog = false"
        >
          Nem
        </v-btn>
        <v-btn
          color="green"
          text
          @click="delDraft()"
          :disabled="del_draft_loading"
          :loading="del_draft_loading"
        >
          Igen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

    <v-dialog
      v-model="refresh_dialog"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h6">
          Biztosan frissíti a megosztási hivatkozást?
        </v-card-title>
        <v-card-text>
          A hivatkozás frissítésével a korábban megosztott letöltési címek többé nem lesznek elérhetők, azokat újra kell küldenie!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            text
            @click="refresh_dialog = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="red"
            text
            @click="refreshHash()"
          >
            Igen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="send_dialog"
      persistent
      max-width="450"
    >
      <v-card>
        <v-card-title class="text-h6">
          Hivatkozás küldése e-mailen
        </v-card-title>
        <v-card-text>
          <v-form
            ref="emailShare_form"
            v-model="emailShare_valid"
          >
          <v-col
             cols="12"
           >
             <v-text-field
               outlined
               v-model="mailForSharing"
               :rules="[rules.email]"
               label="E-mail cím"
               append-icon="mail_outline"
             ></v-text-field>
           </v-col>
         </v-form>
         <p>Az üzenet feladója az "Ellenőrző funkciók" menüpontban beállított e-mail cím lesz ({{email}})</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="send_dialog = false, mailForSharing = null, hashToShare = null, $refs.emailShare_form.resetValidation()"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!emailShare_valid || shareLoading"
            :loading = "shareLoading"
            @click="shareViaMail()"
          >
            Küld
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  <v-snackbar
    v-model="error_snackbar"
    color="red"
  >
    {{ error_message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        text
        v-bind="attrs"
        @click="error_snackbar = false"
      >
        bezár
      </v-btn>
    </template>
  </v-snackbar>

  <v-snackbar
    v-model="success_snackbar"
  >
    {{ success_message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="pink"
        text
        v-bind="attrs"
        @click="success_snackbar = false"
      >
        bezár
      </v-btn>
    </template>
  </v-snackbar>

  </div>
</template>

<script>
import EventBus from '@/EventBus';
import firebase from '@/firebaseinit';
import { getAuth } from "firebase/auth";
const auth = getAuth(firebase);

  export default {
    data: () => ({
        customers: [],
        uid: '',
        error_snackbar: false,
        success_snackbar: false,
        error_message: '',
        success_message: '',
        required: [
          v => !!v || 'A mező kitöltése kötelező!'
        ],
        preloader: false,
        end_time: '',
        end_time_menu: false,
        start_time: '',
        start_time_menu: false,
        timeRules: [
          v => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Hibás idő formátum!',
        ],
        auto_save: true,
        sunday_notif: true,
        mail_notif: true,
        email: '',
        rules: {
          required: value => !!value || 'Kötelező mező',
          w_email: value => (!value || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) || 'Hibás adószám formátum!',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Hibás e-mail cím formátum!'
          },
        },
        mail_valid: true,
        sharing: false,
        custom_sharing: false,
        workersSharing: false,
        selected_workers_toShare: [],
        start_sharing: '',
        end_sharing: '',
        sharing_url: '',
        delete_wshare_lines: [],
        refresh_dialog: false,
        widToHash: '',
        event_types_select: [],
        selected_event_type: '',
        event_code: '',
        event_types: [
          {text: 'Munkanap', value: 'work'},
          {text: 'Szabadnap', value: 'free'}
        ],
        event_type: '',
        event_color: '#00BCD4FF',
        hidden_event: false,
        allow_duplicate: false,
        event_text_color: '#FFFFFF',
        event_valid: true,
        worker_email: '',
        custom_event_type_id: '',
        del_eventtype_dialog: false,
        del_eventtype_loading: false,
        save_notif: '',
        selected_draft_id: '',
        selected_draft: [],
        draft_id_to_del: '',
        drafts: [],
        dows: [
          {text: 'Hétfő', value: '1'},
          {text: 'Kedd', value: '2'},
          {text: 'Szerda', value: '3'},
          {text: 'Csütörtök', value: '4'},
          {text: 'Péntek', value: '5'},
          {text: 'Szombat', value: '6'},
          {text: 'Vasárnap', value: '7'},
        ],
        df_start_time_menu: [],
        df_end_time_menu: [],
        df_timeRules: [
          v => !!v || 'Kötelező mező!',
          v => /^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(v) || 'Hibás idő formátum!',
        ],
        delete_draft_lines: [],
        drafts_valid: true,
        del_draft_dialog: false,
        del_draft_loading: false,
        selected_draft_for_worker: '',
        disable_auto_schedule: false,
        weekly_rest: '',
        avg_rest: '',
        send_dialog: false,
        emailShare_valid: true,
        mailForSharing: '',
        hashToShare: '',
        shareLoading: false,
        coloring_pages: false,
    }),
    methods: {
      getJWT: async function() {
        var jwt = "";
        await auth.currentUser.getIdToken().then(function(idToken) {
          jwt = idToken;
        }).catch(function(error) {
          // Handle error
          console.log(error);
        });
        return jwt;
      },
      getWorkers: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/workersForDatas', {
        token: jwt
        })
        .then((response) => {
          this.customers = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      saveAutoSettings: async function () {
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/saveAutoSettings_220426', {
        token: jwt,
        auto_save: this.auto_save,
        sunday_notif: this.sunday_notif,
        mail_notif: this.mail_notif,
        save_notif: this.save_notif,
        weekly_rest: this.weekly_rest,
        avg_rest: this.avg_rest,
        email: this.email,
        coloring_pages: this.coloring_pages
        })
        .then((response) => {
          this.preloader = false;
          this.success_message = response.data
          this.success_snackbar = true;
          this.checkAutoSettings()
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      checkAutoSettings: async function () {
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/checkAutoSettings', {
        token: jwt
        })
        .then((response) => {
          this.preloader = false;
          this.auto_save = response.data[0]['autosave'];
          this.sunday_notif = response.data[0]['sunday_notif'];
          this.mail_notif = response.data[0]['mail_notif'];
          this.save_notif = response.data[0]['save_notif'];
          this.weekly_rest = response.data[0]['weekly_rest'];
          this.avg_rest = response.data[0]['avg_rest'];
          this.email = response.data[0]['email'];
          this.coloring_pages = response.data[0]['doc_colors'];
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      saveSharingSettings: async function () {
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/saveSharingSettings', {
        token: jwt,
        sharing: this.sharing,
        custom_sharing: this.custom_sharing,
        start_sharing: this.start_sharing,
        end_sharing: this.end_sharing,
        workersSharing: this.workersSharing,
        workerstoShare: this.selected_workers_toShare,
        deleteWorkersShare: this.delete_wshare_lines
        })
        .then((response) => {
          this.preloader = false;
          this.success_message = response.data
          this.success_snackbar = true;
          this.checkSharingSettings()
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      refreshHash: async function () {
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/hashUpdate', {
        token: jwt,
        worker_id: this.widToHash
        })
        .then((response) => {
          this.preloader = false;
          this.success_message = response.data
          this.success_snackbar = true;
          this.checkSharingSettings()
          this.widToHash = '';
          this.refresh_dialog = false
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      checkSharingSettings: async function () {
        this.preloader = true
        const jwt = await this.getJWT();
        this.$http.post('/functions/checkSharingSettings', {
        token: jwt
        })
        .then((response) => {
          this.preloader = false;
          if (response.data['settings'] != '') {
            this.sharing = response.data['settings'][0]['active'];
            if (response.data['settings'][0]['start'] || response.data['settings'][0]['end']) {
              this.custom_sharing = true;
            } else {
              this.custom_sharing = false;
            }
            this.start_sharing = response.data['settings'][0]['start'];
            this.end_sharing = response.data['settings'][0]['end'];
          }
          this.sharing_url = response.data['url'];
          if (response.data['workers'] != '') {
            if (response.data['workers'][0]['active'] == 1) {
              this.workersSharing = true
            } else {
              this.workersSharing = false
            }
            this.selected_workers_toShare = response.data['workers'];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getSelectEventTypes: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/event_types_owned', {
        token: jwt
        })
        .then((response) => {
          this.event_types_select = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      selectedEventTypes: async function () {
        this.custom_event_type_id = null;
        this.event_code = '';
        this.event_type = '';
        this.event_color = '00BCD4FF';
        this.event_text_color = '#FFFFFF';
        this.hidden_event = false;
        this.allow_duplicate = false;
        const jwt = await this.getJWT();
        this.$http.post('/datas/selected_event_type', {
        token: jwt,
        selected_event_type: this.selected_event_type['type_id']
        })
        .then((response) => {
          this.event_code = response.data[0]['code'];
          this.event_type = response.data[0]['tipus'];
          this.event_color = response.data[0]['color'];
          this.event_text_color = response.data[0]['text_color'];
          this.hidden_event = response.data[0]['hidden'];
          this.allow_duplicate = response.data[0]['allow_duplicate'];
          this.custom_event_type_id = response.data[0]['type_id'];
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      saveEventTypes: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/functions/saveCustomEvents', {
        token: jwt,
        selected_event_type: this.selected_event_type,
        event_code: this.event_code,
        event_type: this.event_type,
        event_color: this.event_color,
        hidden_event: this.hidden_event,
        allow_duplicate: this.allow_duplicate,
        event_text_color: this.event_text_color
        })
        .then((response) => {
          this.getSelectEventTypes();
          this.refreshEventTypes();
          this.refreshSelectEventTypes();
          this.refreshDocEventTypes();
          if (response.data['success'] == true) {
            this.success_message = response.data['message'];
            this.success_snackbar = true;
          } else if (response.data['success'] == false) {
            this.error_message = response.data['message'];
            this.error_snackbar = true;
          }
          this.selected_event_type = null;
          this.custom_event_type_id = null;
          this.event_code = '';
          this.event_type = '';
          this.event_color = '00BCD4FF';
          this.event_text_color = '#FFFFFF';
          this.hidden_event = false;
          this.allow_duplicate = false;
          this.$refs.event_form.resetValidation()
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      delEventTypes: async function () {
        this.del_eventtype_loading = true;
        const jwt = await this.getJWT();
        this.$http.post('/functions/delCustomEvents', {
        token: jwt,
        custom_event_type_id: this.custom_event_type_id
        })
        .then((response) => {
          this.del_eventtype_loading = false;
          this.del_eventtype_dialog = false;
          this.getSelectEventTypes();
          this.refreshEventTypes();
          this.refreshSelectEventTypes();
          this.refreshDocEventTypes();
          if (response.data['success'] == true) {
            this.success_message = response.data['message'];
            this.success_snackbar = true;
          } else if (response.data['success'] == false) {
            this.error_message = response.data['message'];
            this.error_snackbar = true;
          }
          this.selected_event_type = null;
          this.custom_event_type_id = null;
          this.event_code = '';
          this.event_type = '';
          this.event_color = '00BCD4FF';
          this.event_text_color = '#FFFFFF';
          this.hidden_event = false;
          this.allow_duplicate = false;
          this.$refs.event_form.resetValidation()
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getDrafts: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/datas/drafts', {
        token: jwt
        })
        .then((response) => {
          this.drafts = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      getSelectedDraft: async function () {
        this.delete_draft_lines = [];
        this.draft_id_to_del = '';
        const jwt = await this.getJWT();
        this.$http.post('/datas/selected_draft', {
        token: jwt,
        draft_id: this.selected_draft_id
        })
        .then((response) => {
          this.selected_draft = response.data;
          if (this.selected_draft != '') {
            this.draft_id_to_del = response.data[0]['draft_id'];
          }
          this.disable_days();
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      saveDraft: async function () {
        const jwt = await this.getJWT();
        this.$http.post('/functions/saveDrafts', {
        token: jwt,
        selected_draft_id: this.selected_draft_id,
        selected_draft: this.selected_draft,
        delete_draft_lines: this.delete_draft_lines
        })
        .then((response) => {
          console.log(response.data);
          this.getDrafts();
          this.refreshDraftWorkers();
          this.refreshDraftSchedule();
          //this.refreshEventTypes();
          //this.refreshSelectEventTypes();
          if (response.data['success'] == true) {
            this.success_message = response.data['message'];
            this.success_snackbar = true;
          } else if (response.data['success'] == false) {
            this.error_message = response.data['message'];
            this.error_snackbar = true;
          }

          this.$refs.drafts_form.resetValidation()
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      delDraft: async function () {
        this.del_draft_loading = true;
        const jwt = await this.getJWT();
        this.$http.post('/functions/delDraft', {
        token: jwt,
        draft_id_to_del: this.draft_id_to_del
        })
        .then((response) => {
          this.del_draft_loading = false;
          this.del_draft_dialog = false;
          this.getDrafts();
          this.refreshDraftWorkers();
          this.refreshDraftSchedule();
          this.selected_draft_id = '';
            this.success_message = response.data;
            this.success_snackbar = true;
          this.$refs.drafts_form.resetValidation()
        })
        .catch(function (error) {
          console.log(error);
        });
      },
      shareViaMail: async function () {
        this.shareLoading = true;
        const jwt = await this.getJWT();
        this.$http.post('/functions/send_sharedSchedule', {
        token: jwt,
        hashToShare: this.hashToShare,
        mailForSharing: this.mailForSharing,
        mailFrom: this.email
        })
        .then((response) => {
          this.shareLoading = false;
          if (response.data['success'] == true) {
            this.success_message = response.data['message'];
            this.success_snackbar = true;
          } else if (response.data['success'] != true) {
            this.error_message = response.data['message'];
            this.error_snackbar = true;
          }
          this.send_dialog = false;
          this.mailForSharing = '';
          this.hashToShare = '';
          this.$refs.emailShare_form.resetValidation()
        })
        .catch(function (error) {
          this.shareLoading = false;
          console.log(error);
        });
      },
      refreshEventTypes() {
        EventBus.$emit('refreshEventTypes')
      },
      refreshSelectEventTypes() {
        EventBus.$emit('refreshSelectEventTypes')
      },
      refreshDraftWorkers() {
        EventBus.$emit('refreshDraftWorkers')
      },
      refreshDraftSchedule() {
        EventBus.$emit('refreshDraftSchedule')
      },
      refreshDocEventTypes() {
        EventBus.$emit('refreshDocEventTypes')
      },
      hourDiff() {
        let self = this

        this.selected_draft.forEach(function(element, index) {
          var date = new Date().toLocaleDateString('en-US');
          var timeStart = new Date(date + " " + element.start_time).getTime();
          var timeEnd = new Date(date + " " + element.end_time).getTime();

          var hourDiff = timeEnd - timeStart;
          hourDiff = Math.round(hourDiff / 60000);
          hourDiff = hourDiff/60;

          if (hourDiff < 0) {
              hourDiff = 24 + hourDiff;
           }

           if (hourDiff > 6 && hourDiff <= 9 && element.rest < 20) {
             self.selected_draft[index]['rest'] = 20;
           }
           if (hourDiff > 9 && element.rest < 45) {
             self.selected_draft[index]['rest'] = 45;
           }
           if (hourDiff <= 6) {
             self.selected_draft[index]['rest'] = '';
           }

        })
     },
     addWorkerToShare() {
         this.selected_workers_toShare.push({
           worker_id: null,
           start: null,
           end: null
         })
     },
     removeWorkerToShare (lineId, worker_id) {
       this.selected_workers_toShare.splice(lineId, 1)
       this.delete_wshare_lines.push({
         line_id: worker_id,
       })
       console.log(this.delete_wshare_lines);
     },
     addDraft() {
         this.selected_draft.push({
           day_no: null,
           start_time: null,
           end_time: null,
           rest: null,
         })
     },
     removeDraft (lineId, draft_id) {
       this.selected_draft.splice(lineId, 1)
       this.delete_draft_lines.push({
         line_id: draft_id,
       })
       this.disable_days();
     },
     copyToClipboard: async function () {
       try {
         await navigator.clipboard.writeText(this.sharing_url);
         this.success_message = "Hivatkozás a vágólapra másolva!";
         this.success_snackbar = true;
       } catch($e) {
         this.error_message = "Hiba történt!";
         this.error_snackbar = true;
       }
     },
     copyWorkerToClipboard: async function (hash) {
       try {
         await navigator.clipboard.writeText(hash);
         this.success_message = "Hivatkozás a vágólapra másolva!";
         this.success_snackbar = true;
       } catch($e) {
         this.error_message = "Hiba történt!";
         this.error_snackbar = true;
       }
     },
     disable_days() {
       let self = this
       self.dows.forEach(function(element) {
         element['disable'] = false;
       })
       self.selected_draft.forEach(function(element) {
         var day_no = element['day_no']-1;
         self.dows[day_no]['disable'] = true;
       })
     }
    },
    mounted() {
      this.getWorkers()
      this.checkAutoSettings()
      this.checkSharingSettings()
      this.getSelectEventTypes()
      this.getDrafts()
    },
    watch: {
    selected_event_type() {
      this.selectedEventTypes()
    },
    selected_draft_id() {
      this.getSelectedDraft()
    },
    },
    created() {
      EventBus.$on('refreshSettings', () => {
            this.checkAutoSettings();
      }),
      EventBus.$on('refreshWorkersonSettings', () => {
            this.getWorkers();
      })
    },
    computed: {
    },
  }
</script>

<style lang="css">
</style>
