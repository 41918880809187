import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios';
import VueMask from 'v-mask'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueGtag from "vue-gtag"

Vue.use(VueGtag, {
  config: { id: "AW-304345301" }
});

Vue.use(VueReCaptcha, {
  siteKey: '6LdWrGcdAAAAADvQcUz0cHi0Xl-hDhGkU10Vz536',
  loaderOptions: {
  useRecaptchaNet: true,
  autoHideBadge: true
  }
})

Vue.use(VueMask);

const base = axios.create({
  //baseURL: 'https://uvrdev.csigaver.hu/diak_services' // TESZT SZERVER
  baseURL: 'https://app.munkaora.hu/services'
  //baseURL: 'https://elszam.csigaver.hu/diak_services' //PROD URL
})

Vue.prototype.$http = base

Vue.config.productionTip = false

    new Vue({
      router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
