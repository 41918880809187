<template>
  <div class="login text-center">
    <v-container>

      <div v-if="!user">
      <br>
      <v-row justify="center">
      <v-col
          cols="12"
          md="6"
          lg="6"
          sm="8"
          xl="4"
      >
      <v-card
        class="mx-auto"
        max-width="344"
        outlined
      >
        <v-card-text>
          <v-form
            ref="login_form"
            v-model="valid"
          >
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="login_user"
              label="E-mail cím"
              outlined
              :rules="emailRules"
              required
              @keyup.enter="Login()"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="password"
              label="Jelszó"
              outlined
              :rules="passRules"
              required
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              counter
              @click:append="show1 = !show1"
              @keyup.enter="Login()"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
           <v-checkbox
             v-model="accept"
           >
           <template v-slot:label>
             <div class="text-caption">
               A regisztrációval és a program használatával elfogadom az
                   <a
                     target="_blank"
                     href="https://munkaora.hu/Munkaora_GDPR.pdf"
                     @click.stop
                   >
                     Adatkezelési tájékoztatóban</a> és az
                   <a
                     target="_blank"
                     href="https://munkaora.hu/Munkaora_ASZF.pdf"
                     @click.stop
                   >
                     ÁSZF-ben</a>
                 foglaltakat!
               </div>
             </template>
           </v-checkbox>
           </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="ma-2"
            outlined
            color="indigo"
            :disabled="!valid || singinLoading"
            :loading="singinLoading"
            @click="Login()"
          >
            Bejelentkezés
          </v-btn>
          <v-btn
            class="ma-2"
            outlined
            color="red"
            :disabled="!valid || regButtonLoading || !accept"
            :loading="regButtonLoading"
            @click="Register()"
          >
            Regisztráció
          </v-btn>
        </v-card-actions>
        <v-btn
          class="ma-2"
          outlined
          color="green"
          :disabled="!checkMail"
          @click="passwordReset()"
        >
          Elfelejtett jelszó
        </v-btn>
      </v-card>
      </v-col>
      <!--
      <v-col
        cols="12"
        md="6"
        lg="6"
        sm="8"
        xl="4"
      >
      <v-alert
        border="left"
        colored-border
        color="red"
        outlined
      >
        Az alkalmazás kipróbálásához nem szükséges e-mail cím megadása (regisztrálása), választhat "név nélküli" bejelentkezési módot is!
        <v-checkbox
          v-model="accept_anonym"
        >
        <template v-slot:label>
          <div class="text-caption">
            A regisztrációval és a program használatával elfogadom az
                <a
                  target="_blank"
                  href="https://munkaora.hu/Munkaora_GDPR.pdf"
                  @click.stop
                >
                  Adatkezelési tájékoztatóban</a> és az
                <a
                  target="_blank"
                  href="https://munkaora.hu/Munkaora_ASZF.pdf"
                  @click.stop
                >
                  ÁSZF-ben</a>
              foglaltakat!
            </div>
          </template>
        </v-checkbox>
        <v-btn
          v-if="!user"
          class="ma-2"
          outlined
          color="indigo"
          @click="dialog = true"
          :loading="abLoading"
          :disabled="abLoading || !accept_anonym"
        >
          Bejelentkezés név nélkül
        </v-btn>
      </v-alert>
      </v-col>
      -->
      
      </v-row>
      </div>

      <div v-if="user">
        <br>
        <v-card
          class="mx-auto"
          max-width="500"
          outlined
        >
        <v-container>
        <h2>Bejelentkezve, mint:</h2>
        <h3 class="font-weight-light">{{username}}</h3>
        <br>
        <v-row v-if="!anonym">
          <v-col cols="12" md="6">
          <v-btn
            class="ma-2"
            outlined
            color="primary"
            @click="eMail_dialog = true"
          >
            E-mail cím módosítás
          </v-btn>
        </v-col>
        <v-col cols="12" md="6">
        <v-btn
          class="ma-2"
          outlined
          color="primary"
          @click="password_dialog = true"
        >
          Jelszó módosítás
        </v-btn>
        </v-col>
      </v-row>
      <v-btn
        v-if="anonym"
        class="ma-2"
        outlined
        color="primary"
        @click="createUser_dialog = true"
      >
        Regisztráció véglegesítése
      </v-btn>
      <br>
      <v-btn
        v-if="user"
        class="ma-2"
        outlined
        color="red"
        @click="Logout()"
      >
        Kilépés
      </v-btn>
      </v-container>
      </v-card>
      <br>
      <v-card
        class="mx-auto"
        max-width="500"
        outlined
      >
      <v-container>
      <h3>Előfizetés lejár:</h3>
      <h3 class="font-weight-light">{{expiration}}</h3>
      <br>
      <v-row justify="center">
        <v-col cols="12" md="6">
        <v-btn
          class="ma-2"
          outlined
          color="green"
          @click="subscription = true"
          :disabled="anonym"
        >
          Előfizetés hosszabbítás
        </v-btn>
      </v-col>
      <p v-if="anonym">(Előfizetéshez regisztrált fiók szükséges!)</p>
      <v-col cols="12" md="6" v-if="user && !anonym">
        <v-btn
          class="ma-2"
          outlined
          color="red"
          @click="del_account_dialog = true"
          :loading="delAcLoading"
          :disabled="delAcLoading"
        >
          Fiók törlése
        </v-btn>
      </v-col>
    </v-row>
    </v-container>
    </v-card>
      </div>

    </v-container>

    <v-dialog
    v-model="dialog"
    width="500"
    persistent
    >

      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          Figyelem!
        </v-card-title>

        <v-container>
          <br>
          Hogyha ezt a bejelentkezési módot választja, a rendszerben rögzített teszt adatokat csak addig éri el, amíg ki nem jelentkezik, ezt követően elvesznek!
          (Kijelentkezés előtt még lehetősége lesz végleges regisztráció létrehozására is.)
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="dialog = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="green"
            outlined
            @click="dialog = false, AnonymousLogin()"
          >
            Rendben
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
    v-model="subscription"
    width="500"
    persistent
    scrollable
    >

      <v-card>
        <v-card-title class="text-h5 white--text primary">
          Előfizetés
        </v-card-title>

        <v-container>
          Az éves előfizetés mindenkor aktuális díját az <a target="_blank" href="https://munkaora.hu/Munkaora_ASZF.pdf" @click.stop>ÁSZF</a> 3.1. pontja határozza meg.
          <br><br>
          Az előfizetés kezdeményezését követően egy díjbekérő számlát fogunk Önnek küldeni a regisztrált e-mail címére.
          Az összeg befizetését átutalással tudja teljesíteni.
          <br><br>
          Az utalás könyvelését követően előfizetése - a korábbi lejárati időhöz viszonyítva - egy évvel meghosszabbodik.
          <br><br>
          <i>(Előfizetést bármikor kezdeményezhet, az újabb egy éves hozzáférés nem a befizetés napjától, hanem a korábbi lejárattól indul!)</i>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="subscription = false, subscription_loader = false"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="green"
            outlined
            @click="sendSubscription()"
            :loading="subscription_loader"
            :disabled="subscription_loader"
          >
            Előfizetek
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
    v-model="del_account_dialog"
    width="500"
    persistent
    >

      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          Figyelem!
        </v-card-title>

        <v-container>
          <br>
          A fiók törlésével minden rögzített adat elveszik! A művelet nem vonható vissza!
          <br><br>
          <v-form
            ref="del_form"
            v-model="delAcvalid"
          >
          <v-row>
          <v-col
            cols="8"
          >
          <v-text-field
            v-model="passwordToDelete"
            label="Jelszó"
            outlined
            :rules="passRules"
            required
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            name="input-10-1"
            counter
            @click:append="show3 = !show3"
          ></v-text-field>
          </v-col>
          </v-row>
          </v-form>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            @click="del_account_dialog = false, $refs.del_form.resetValidation()"
          >
            Mégsem
          </v-btn>
          <v-btn
            color="red"
            outlined
            :disabled="!delAcvalid"
            @click="deleteAccount()"
          >
            Fiók törlése
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="eMail_dialog"
      persistent
      width="400px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>E-mail cím módosítása</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="eMail_dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form
              ref="email_form"
              v-model="email_valid"
            >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="new_email"
                  label="Új e-mail cím"
                  :rules="emailRules"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="password"
                  label="Jelszó"
                  outlined
                  :rules="passRules"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  counter
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            :disabled="!email_valid || emailChangeLoading"
            :loading="emailChangeLoading"
            @click="updateEmail()"
          >
            Rögzít
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="password_dialog"
      persistent
      width="400px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Jelszó módosítása</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="password_dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form
              ref="password_form"
              v-model="psw_valid"
            >
            <v-row>
              <v-col
                cols="12"
              >
              <v-text-field
                v-model="password"
                label="Régi jelszó"
                outlined
                :rules="passRules"
                required
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                counter
                @click:append="show1 = !show1"
              ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="new_password"
                  label="Új jelszó"
                  outlined
                  :rules="passRules"
                  required
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-1"
                  counter
                  @click:append="show2 = !show2"
                ></v-text-field>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            :disabled="!psw_valid || emailChangeLoading"
            :loading="emailChangeLoading"
            @click="updatePassword()"
          >
            Rögzít
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="createUser_dialog"
      persistent
      width="400px"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-toolbar-title>Felhasználó létrehozása</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="createUser_dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form
              ref="create_form"
              v-model="create_valid"
            >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="login_user"
                  label="E-mail cím"
                  :rules="emailRules"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="password"
                  label="Jelszó"
                  outlined
                  :rules="passRules"
                  required
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  counter
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            outlined
            :disabled="!create_valid || emailChangeLoading"
            :loading="emailChangeLoading"
            @click="userFromAnonymus()"
          >
            Létrehoz
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="error_snackbar"
      color="red"
    >
      {{ error_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="error_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="success_snackbar"
    >
      {{ success_message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="success_snackbar = false"
        >
          bezár
        </v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<script>
import EventBus from '@/EventBus';
import firebase from '@/firebaseinit';
import { getAuth, signInAnonymously, signOut, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateEmail, reauthenticateWithCredential, EmailAuthProvider, updatePassword, linkWithCredential, sendPasswordResetEmail, deleteUser } from "firebase/auth";
const auth = getAuth(firebase);

export default {
  name: 'Login',
  components: {
  },
  data: () => ({
      drawer: null,
      user: '',
      username: '',
      login_user: '',
      password: '',
      dialog: false,
      valid: true,
      emailRules: [
        v => !!v || 'Kötelező kitölteni!',
        v => /.+@.+\..+/.test(v) || 'Nem megfelelő e-mail cím formátum!',
      ],
      passRules: [
        v => !!v || 'Kötelező kitölteni!',
        v => (v && v.length >= 6) || 'Legalább 6 karakter!',
      ],
      error_snackbar: false,
      error_message: '',
      success_message: '',
      success_snackbar: false,
      recaptcha_token: '',
      recaptcha_valid: false,
      abLoading: false,
      singinLoading: false,
      regButtonLoading: false,
      show1: false,
      show2: false,
      show3: false,
      email_valid: true,
      new_email: '',
      eMail_dialog: false,
      emailChangeLoading: false,
      password_dialog: false,
      psw_valid: true,
      new_password: '',
      anonym: '',
      createUser_dialog: false,
      create_valid: true,
      del_account_dialog: false,
      delete_checkbox: false,
      delAcLoading: false,
      passwordToDelete: '',
      delAcvalid: true,
      accept: false,
      accept_anonym: false,
      expiration: '',
      subscription: false,
      subscription_loader: false,
  }),
  methods: {
  async recaptcha() {
    await this.$recaptchaLoaded()
    this.recaptcha_token = await this.$recaptcha('login')
  },
  getJWT: async function() {
    var jwt = "";
    await auth.currentUser.getIdToken().then(function(idToken) {
      jwt = idToken;
    }).catch(function(error) {
      // Handle error
      console.log(error);
    });
    return jwt;
  },
  recaptchaValidation: async function () {
    await this.recaptcha()
    this.$http.post('/recaptcha', {
    token: this.recaptcha_token
    })
    .then((response) => {
      if (response.data['success'] == 'true') {
        this.recaptcha_valid = true
      }
      //console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  AnonymousLogin: async function () {
    this.abLoading = true
    await this.recaptcha()

    this.$http.post('/recaptcha', {
    token: this.recaptcha_token
    })
    .then((response) => {
      if (response.data['success'] == 'true') {

        signInAnonymously(auth)
        .then((user) => {
          this.createSettings(user.user.accessToken, user.user.email);
          this.mainNotif(user.user.accessToken, user.user.email);
          this.User()
          this.googleCode()
          //console.log(user);
          this.$router.push({ path: '/' });
          //var href = location.origin;
          //location.assign(href);
        })
        .catch((error) => {
          console.log(error);
        });
        this.abLoading = false
      } else {
        this.error_message = response.data['message'];
        this.error_snackbar = true;
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  },
  userFromAnonymus: function () {
    this.emailChangeLoading = true;
    const credential = EmailAuthProvider.credential(this.login_user, this.password);
    linkWithCredential(auth.currentUser, credential)
    .then((user) => {
      this.emailChangeLoading = false;
      this.createSettings(user.user.accessToken, user.user.email);
      this.mainNotif(user.user.accessToken, user.user.email);
      this.User()
      this.refreshUserState()
      this.success_message = 'Sikeres rögzítés!';
      this.success_snackbar = true;
      this.login_user = '';
      this.password = '';
      this.createUser_dialog = false;
    }).catch((error) => {
      this.error_snackbar = true;
      if (error.code == 'auth/email-already-in-use') {
        this.error_message = 'A megadott e-mail cím már foglalt!';
      } else {
        this.error_message = "Hiba történt a regisztráció során! " + error.code;
      }
      console.log(error.code);
      this.emailChangeLoading = false;
      console.log("Error upgrading anonymous account", error);
    });
  },
  Register: async function () {
    this.regButtonLoading = true;
    await this.recaptcha()

    this.$http.post('/recaptcha', {
    token: this.recaptcha_token
    })
    .then((response) => {
      if (response.data['success'] == 'true') {

        createUserWithEmailAndPassword(auth, this.login_user, this.password)
          .then((user) => {
            this.createSettings(user.user.accessToken, user.user.email);
            this.mainNotif(user.user.accessToken, user.user.email);
            console.log(user);
            // Signed in
            //console.log(userCredential.user);
            this.User();
            this.googleCode();
            this.success_message = 'Sikeres regisztráció!';
            this.success_snackbar = true;
            this.$router.push({ path: '/' });
            //var href = location.origin;
            //location.assign(href);
          })
          .catch((error) => {
            this.error_snackbar = true;
            if (error.code == 'auth/email-already-in-use') {
              this.error_message = 'A megadott e-mail cím már használatban van!';
            } else {
              this.error_message = 'Hiba történt a regisztráció során!';
            }
            console.log('Hibakód: ' + error.code);
            console.log('Hibaüzenet: ' + error.message);
            // ..
          });
        this.regButtonLoading = false;
      } else {
        this.error_message = response.data['message'];
        this.error_snackbar = true;
      }
    })
    .catch(function (error) {
      console.log(error);
    });

  },
  Login: async function () {
    this.singinLoading = true;
    await this.recaptcha()

    this.$http.post('/recaptcha', {
    token: this.recaptcha_token
    })
    .then((response) => {
      if (response.data['success'] == 'true') {

        signInWithEmailAndPassword(auth, this.login_user, this.password)
          .then(() => {
            // Signed in
            //console.log(userCredential.user);
            this.User();
            this.success_message = 'Sikeres bejelentkezés!';
            this.success_snackbar = true;
            var href = location.origin;
            location.assign(href);
          })
          .catch((error) => {
            this.error_snackbar = true;
            if (error.code == 'auth/user-not-found') {
              this.error_message = 'Nincs ilyen felhasználó!';
            } else if (error.code == 'auth/wrong-password') {
              this.error_message = 'A megadott jelszó hibás!';
            } else if (error.code == 'auth/too-many-requests') {
              this.error_message = 'Túl sok bejelentkezési kísérlet! (Fiókja átmenetileg le lett tiltva!)';
            } else {
              this.error_message = 'Hiba történt a bejelentkezés során!';
            }
            console.log('Hibakód: ' + error.code);
            console.log('Hibaüzenet: ' + error.message);
          });
        this.singinLoading = false;
      } else {
        this.error_message = response.data['message'];
        this.error_snackbar = true;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  Logout: function () {
    signOut(auth)
    .then(() => {
      this.User()
      var href = location.origin;
      location.assign(href);
    })
    .catch((error) => {
      console.log(error);
    });
  },
  updateEmail: function () {
    this.emailChangeLoading = true;
    const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        this.password
    )
    reauthenticateWithCredential(auth.currentUser, credential).then((user) => {
      updateEmail(auth.currentUser, this.new_email).then(() => {
        this.emailChangeLoading = false;
        this.createSettings(user.user.accessToken, user.user.email);
        this.User();
        this.success_message = 'Sikeres módosítás!';
        this.success_snackbar = true;
        this.new_email = '';
        this.password = '';
        this.eMail_dialog = false;
      }).catch((error) => {
        this.emailChangeLoading = false;
        this.error_snackbar = true;
        this.error_message = 'Hiba történt a regisztráció során! ' + error.code;
        console.log(error.code);
      });
    }).catch((error) => {
      this.emailChangeLoading = false;
      this.error_snackbar = true;
      if (error.code == 'auth/wrong-password') {
        this.error_message = 'A megadott jelszó hibás!';
      } else {
        this.error_message = "Hiba történt a regisztráció során! " + error.code;
      }
      console.log(error.code);
    });
  },
  updatePassword: function () {
    this.emailChangeLoading = true;
    const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        this.password
    )
    reauthenticateWithCredential(auth.currentUser, credential).then(() => {
      updatePassword(auth.currentUser, this.new_password).then(() => {
        this.emailChangeLoading = false;
        this.User();
        this.success_message = 'Sikeres módosítás!';
        this.success_snackbar = true;
        this.new_password = '';
        this.password = '';
        this.password_dialog = false;
      }).catch((error) => {
        this.emailChangeLoading = false;
        this.error_snackbar = true;
        this.error_message = 'Hiba történt a regisztráció során! ' + error.code;
        console.log(error.code);
      });
    }).catch((error) => {
      this.emailChangeLoading = false;
      this.error_snackbar = true;
      if (error.code == 'auth/wrong-password') {
        this.error_message = 'A megadott jelszó hibás!';
      } else {
        this.error_message = "Hiba történt a regisztráció során! " + error.code;
      }
      console.log(error.code);
    });
  },
  reauthenticateWithCredential: function () {
    const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        this.password
    )
    reauthenticateWithCredential(auth.currentUser, credential).then(() => {
      console.log('User re-authenticated.');
    }).catch((error) => {
      console.log(error);
    });
  },
  passwordReset() {
    sendPasswordResetEmail(auth, this.login_user)
      .then(() => {
        this.success_message = 'A visszaállításhoz szükséges e-mailt elküldtük!';
        this.success_snackbar = true;
      })
      .catch((error) => {
        if (error.code == 'auth/user-not-found') {
          this.error_message = 'Nincs ilyen felhasználó!';
        } else {
          this.error_message = error.message;
        }
        this.error_snackbar = true;
        console.log(error);
      });
  },
  createSettings: function (token, email) {
    this.$http.post('/functions/createSettings', {
    token: token,
    email: email
    })
    .then((response) => {
      this.success_message = response.data;
      this.success_snackbar = true;
      //console.log(response.data);
      this.getSettings();
      this.refreshSettings()
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  mainNotif: function (token, email) {
    this.$http.post('/functions/regNotif', {
    token: token,
    email: email
    })
    .then((response) => {
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  getSettings: async function () {
    const jwt = await this.getJWT();
    this.$http.post('/functions/getSettings', {
    token: jwt
    })
    .then((response) => {
      this.expiration = response.data[0]['expiration'];
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  sendSubscription: async function () {
    this.subscription_loader = true;
    const jwt = await this.getJWT();
    this.$http.post('/functions/send_subscription', {
    token: jwt
    })
    .then((response) => {
      this.subscription_loader = false;
      this.subscription = false;
      if (response.data['success'] == true) {
        this.success_message = response.data['message'];
        this.success_snackbar = true;
      } else {
        this.mail_loader = false;
        this.error_message = response.data['message'];
        this.error_snackbar = true;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  deleteAccount: async function () {
    this.del_account_dialog = false;
    this.delAcLoading = true;
    const credential = EmailAuthProvider.credential(
        auth.currentUser.email,
        this.passwordToDelete
    )
    const jwt = await this.getJWT();
    this.$http.post('/functions/delAccount', {
    token: jwt
    })
    .then((response) => {
      if (response.data['success'] == 'true') {
      reauthenticateWithCredential(auth.currentUser, credential).then(() => {
        deleteUser(auth.currentUser).then(() => {
          this.success_message = 'Sikeres törlés!';
          this.success_snackbar = true;
          this.passwordToDelete = '';
          this.$refs.del_form.resetValidation()
        }).catch((error) => {
          this.error_message = 'Váratlan hiba történt!';
          this.error_snackbar = true;
          this.passwordToDelete = '';
          this.$refs.del_form.resetValidation()
          console.log(error);
        });
      }).catch((error) => {
        this.delAcLoading = false;
        this.error_snackbar = true;
        this.passwordToDelete = '';
        this.$refs.del_form.resetValidation()
        if (error.code == 'auth/wrong-password') {
          this.error_message = 'A megadott jelszó hibás!';
        } else {
          this.error_message = "Váratlan hiba történt! " + error.code;
        }
        console.log(error.code);
      });
        this.delAcLoading = false
      } else {
        this.error_message = 'Váratlan hiba történt!';
        this.error_snackbar = true;
        this.passwordToDelete = '';
        this.$refs.del_form.resetValidation()
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  User: function () {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.getSettings()
        this.user = user.uid;
        this.anonym = user.isAnonymous;
        if (user.isAnonymous) {
          this.username = 'Név nélküli felhasználó';
        } else {
          this.username = user.email;
        }
      } else {
        console.log('Nincs bejelentkezett felhasználó!');
        this.user = null;
      }
    });
  },
  googleCode() {
    this.$gtag.event('conversion', {'send_to': 'AW-304345301/QK-MCOSvra0DENXhj5EB'})
  },
  refreshSettings() {
    EventBus.$emit('refreshSettings');
  },
  refreshUserState() {
    EventBus.$emit('refreshUserState');
  }
 },
 mounted(){
  this.User()
  //this.recaptchaValidation()
},
computed: {
  checkMail() {
    if (/.+@.+\..+/.test(this.login_user)) {
      return true;
    } else {
      return false;
    }
  }
}
}
</script>
